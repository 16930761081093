import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Business } from 'src/app/service/Business.service';

@Component({
  selector: 'app-mywin-home',
  templateUrl: './mywin-home.component.html',
  styleUrls: ['./mywin-home.component.scss']
})
export class MywinHomeComponent implements OnInit, OnDestroy {
  currentUser: User;
  fullname = '';
  links = [];
  toggleSideMenu: boolean;
  selectedLink = null;
  selectedChildLink = null;
  currentUrl = '';
  show_my_profile = false;

  all_permissions = [];
  profile_permissions = null;
  subscribed_features = [];
  profile_feature = null;

  scrWidth: any;
  scrWidth1: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 1024) {
      this.toggleSideMenu = true;
    } else {
      this.toggleSideMenu = false;
    }
  }

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private business: Business
  ) {
    this.getScreenSize();

    this.links.push({ path: '/oem/solutions', name: 'My Solutions', icon: 'Organizations', f_icon: 'ic_solutions.svg', children: [] });
    this.links.push({ path: '/oem/tagged-equipments', name: 'OEM Installations', icon: 'Equipment', f_icon: 'ic_installations.svg', children: [] })
    this.links.push({ path: '/oem/spare-parts', name: 'Spare Parts Library', icon: 'Organizations', f_icon: 'ic_trending_up.svg', children: [] });

    this.links.push({
      path: '/customer/customer-installations', name: 'FM Installations', icon: 'My Installations', f_icon: 'ic_installations.svg',
      children: []
    });

    // this.subscribed_features = this.authenticationService.getSubscribedFeatures();
    // let fm_install_feature = this.subscribed_features.find(x => x.name.toLowerCase() == 'installations fm');
    // let oem_install_feature = this.subscribed_features.find(x => x.name.toLowerCase() == 'installations oem');
    // let equipment_feature = this.subscribed_features.find(x => x.name.toLowerCase() == 'my equipment fm');
    // let sol_feature = this.subscribed_features.find(x => x.name.toLowerCase() == 'solutions');
    // let spare_parts_feature = this.subscribed_features.find(x => x.name.toLowerCase() == 'dynamic spare part list');
    // this.profile_feature = this.subscribed_features.find(x => x.name.toLowerCase() == 'profile info');
    // this.all_permissions = this.authenticationService.getDocHubPermissions();
    // let fm_install_permissions = this.all_permissions.find(x => x.name.toLowerCase() == 'installations fm');
    // let oem_install_permissions = this.all_permissions.find(x => x.name.toLowerCase() == 'installations oem');
    // let equipment_permissions = this.all_permissions.find(x => x.name.toLowerCase() == 'my equipment fm');
    // let sol_permissions = this.all_permissions.find(x => x.name.toLowerCase() == 'solutions');
    // let spare_parts_permissions = this.all_permissions.find(x => x.name.toLowerCase() == 'dynamic spare part list');
    // this.profile_permissions = this.all_permissions.find(x => x.name.toLowerCase() == 'profile info');

    // if (sol_permissions) {
    //   if (sol_feature?.mapped && sol_permissions.view) {
    //     this.links.push({ path: '/oem/solutions', name: 'My Solutions', icon: 'Organizations', f_icon: 'fa fa-briefcase', children: [] });
    //   }
    // }
    // if (oem_install_permissions) {
    //   if (fm_install_feature?.mapped && oem_install_permissions.view) {
    //     this.links.push({ path: '/oem/tagged-equipments', name: 'OEM Installations', icon: 'Equipment', f_icon: 'fa fa-wrench', children: [] })
    //   }
    // }

    // if (spare_parts_permissions.view) {
    //   this.links.push({ path: '/oem/spare-parts', name: 'Spare Parts Library', icon: 'Organizations', f_icon: 'fa fa-cogs', children: [] });
    // }

    // let fm_sub_links = [];
    // if (fm_install_permissions) {
    //   if (fm_install_feature?.mapped && fm_install_permissions.view) {
    //     fm_sub_links.push({ path: '/customer/cust-processline', name: 'My Process Lines' });
    //   }
    // }
    // if (equipment_permissions) {
    //   if (equipment_feature?.mapped && equipment_permissions.view) {
    //     fm_sub_links.push({ path: '/customer/customer-equipments', name: 'My Equipment' });
    //   }
    // }

    // if ((fm_install_feature?.mapped || equipment_feature?.mapped) && (fm_install_permissions.view || equipment_permissions.view)) {
    //   this.links.push({
    //     path: '/customer/cust-processline', name: 'FM Installations', icon: 'My Installations', f_icon: 'fa fa-wrench',
    //     children: [...fm_sub_links]
    //   });
    // }

    this.authenticationService.currentUserSubject.subscribe((profile) => {
      this.currentUser = profile;
      if (this.currentUser) {
        this.fullname = this.currentUser.first_name + ' ' + this.currentUser.last_name;
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.show_my_profile = params['profile'] ? true : false;
    });
    this.business.setFooterSubject(false);
  }

  ngOnDestroy(): void {
    this.business.setFooterSubject(true);
  }

  logout() {
    this.authenticationService.logout();
  }

  getLearningLink() {
    let url = this.router.createUrlTree(['/my-win-dashboard/']);
    window.open(this.router.serializeUrl(url), '_blank');
  }

  onLinkClick(link: any) {
    this.selectedChildLink = null;
    this.selectedLink = link;
    this.clickClosefun();
    if (this.selectedLink.children.length > 0) {
      let childInd = 0;
      if (this.selectedLink.name == 'Library') {
        childInd = 3;
      }
      this.selectedChildLink = this.selectedLink.children[childInd];
    }
    this.router.navigate([link.path], { relativeTo: this.route });
  }

  onChildLinkClick(link: any) {
    this.selectedChildLink = link;
    this.router.navigate([link.path]);
    this.clickClosefun();
  }

  onProfileLinkClick() {
    const link = { path: '/mywinuser-profile', name: 'View Profile', icon: 'user', f_icon: 'fa fa-user', children: [] }
    // this.onLinkClick(link);
    this.show_my_profile = true;
  }

  onCancelProfile() {
    this.show_my_profile = false;
    this.router.navigate(['/mywin-user']);
  }

  clickClosefun() {
    this.scrWidth1 = window.innerWidth;
    if (this.scrWidth1 > 1024) {
      this.toggleSideMenu = true;
    } else {
      this.toggleSideMenu = false;
    }
  }

  onMyLearningClick() {
    const url = 'https://enabling.win/my-win-dashboard?' + 'username=' + this.currentUser.username + '&token=' + this.currentUser.refresh_token;
    window.open(url, '_blank');
  }
}
