import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { HeaderComponent } from './header/header.component';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ResetPwdComponent } from './resetpassword/resetpwd.component';
import { SetPwdComponent } from './setpassword/setpwd.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { DragDropDirective } from './_directives/drag-drop.directive';
// import {AlertComponent} from './_directives/alert.component';
import { ToastrModule } from 'ngx-toastr';
import { EquipmentFilterPipe } from './_filters/equipment-filter.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DialogComponent } from './dialog/dialog.component';
import { SharedModule } from './shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { APP_CONFIG, EW_DI_CONFIG } from './app.config';
import { RegisterComponent } from './register/register.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { GoogleChartsModule } from 'angular-google-charts';
import { MywinHomeComponent } from './mywin/mywin-home/mywin-home.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MywinuserProfileComponent } from './mywin/mywinuser-profile/mywinuser-profile.component';
import { IndividualHomeComponent } from './individual/individual-home/individual-home.component';
import { IndividualModule } from './individual/individual.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CurrencyPipe } from '@angular/common';
import { DocumentViewComponent } from './document-view/document-view.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    ResetPwdComponent,
    SetPwdComponent,
    DragDropDirective,
    EquipmentFilterPipe,
    // AlertComponent,
    DialogComponent,
    RegisterComponent,
    WelcomeComponent,
    IndividualHomeComponent,
    SubscribeComponent,
    PageNotFoundComponent,
    MywinHomeComponent,
    MywinuserProfileComponent,
    ForgotPasswordComponent,
    DocumentViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    NgbModule,
    BrowserAnimationsModule,
    AngularDateTimePickerModule,
    MatIconModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    SharedModule,
    ModalModule.forRoot(),
    ImageCropperModule,
    GoogleChartsModule,
    PopoverModule.forRoot(),
    IndividualModule,
    PdfViewerModule
  ],
  exports: [
    EquipmentFilterPipe,
    // AlertComponent
  ],
  providers: [
    { provide: APP_CONFIG, useValue: EW_DI_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CurrencyPipe
  ],
  entryComponents: [DialogComponent, ConfirmDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
