<div class="m-3 row justify-content-center">
    <div class="col-md-4">
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotSubmit()">
            <div class="panel panel-default">
                <div class="panel-body">
                    <div class="text-center">
                        <h3><i class="fa fa-lock fa-4x"></i></h3>
                        <h2 class="text-center">Forgot Password?</h2>
                        <p>Please enter the email address for your account. A verification code will be send to you.
                            Once you have received the verification code. you will be able to choose a new password for
                            your account.</p>

                        <div class="form-group">
                            <div class="input-group">
                                <!--<label for="username">Username</label>-->
                                <span class="input-group-addon"><i
                                        class="glyphicon glyphicon-envelope color-blue"></i></span>
                                <input type="text" formControlName="username" placeholder="Email" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && forgot.username.errors }" />
                                <div *ngIf="submitted && forgot.username.errors" class="invalid-feedback">
                                    <div *ngIf="forgot.username.errors.required">Email is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button [disabled]="loading" class="btn btn-success btn-block">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Reset Password
                            </button>
                            <button type="button" class="btn btn-outline-secondary btn-block" (click)="onBack()">Back
                            </button>
                            <div class="row mt-3">
                                <div class="col-md-6 text-center text-md-left">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>