import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../service/alert.service';
import { Business } from '../service/Business.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  forgotSubmit = false;

  constructor(
    private businessService: Business,
    private alertService: AlertService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]]
    });
  }

  get forgot() { return this.forgotPasswordForm.controls; }

  onForgotSubmit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.businessService.forgotPassword(this.forgot.username.value)
      .subscribe(
        data => {
          this.alertService.success(data.message, true);
          this.router.navigate(['']);
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
        });
  }

  onBack() {
    this.router.navigate(['/login']);
  }

}
