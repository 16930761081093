import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    private cache = new Map<string, { data: any; timestamp: number }>();
    public cache$ = new BehaviorSubject<any[]>(null);
    private cacheDurationMs = 60000 * 30; // 1min * 30

    set(key: string, data: any[]): void {
        if (this.cache.has(key)) {
            this.cache.delete(key);
            console.log(`Data already exists for key '${key}'. Check delete key bug happened in get method`);
        }
        const currentTime = Date.now();
        this.cache.set(key, { data: data, timestamp: currentTime });
        this.cache$.next(this.cache.get(key)?.data);
    }

    get(key: string): any[] {
        const currentTime = Date.now();
        const obj = this.cache.get(key);
        if (!obj) return undefined;
        let data = null;
        if ((currentTime - obj.timestamp) <= this.cacheDurationMs) {
            data = obj?.data;
        } else {
            this.cache.delete(key);
        }
        this.cache$.next(obj?.data);
        return data;
    }

    clear(key = ''): void {
        if (key) {
            this.cache.delete(key);
        } else {
            this.cache.clear();
        }

        this.cache$.next(null);
    }

    deleteRelatedCacheListIem(list_name: string, id = null) {
        this.cache.forEach((value, key) => {
            debugger;
            if (key.toLowerCase().includes(list_name.toLowerCase())) {
                if (!id) {
                    this.cache.delete(key);
                    return;
                }
                let data_list = []
                console.log(key);
                if (value.data.hasOwnProperty('results')) {
                    data_list = value.data.results;

                } else if (Array.isArray(value.data)) {
                    data_list = value.data;
                }
                if (data_list?.length > 0 && data_list[0].hasOwnProperty('id')) {
                    const remove_index = data_list.findIndex(x => x?.id == id)
                    if (remove_index !== -1) {
                        data_list.splice(remove_index, 1)
                    }
                }
            }
        })
    }

    generateCacheKey(functionName: string, args: string[]): string {
        return `${functionName}_${args.join('_')}`;
    }
}
