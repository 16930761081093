import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn : "root"
  })
export class UtilityHelper {
    constructor() {}

    dateConverter(dateParam:any): string {
        var d = new Date(dateParam),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        // Putting it all together
        return  year + '-' + month + '-' + day + ' ' + '00:00:00';
    }

    isValidJson(controlName:string){
        return (formGroup: FormGroup) => {
            let control = formGroup.controls[controlName];
            if(control.value!=null){
                try {
                    JSON.parse(control.value);
                } catch (e) {
                    control.setErrors({ isValidJson: true });
                    return;
                }
                control.setErrors(null);
            }
        }
    }

    dateCompare(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if(control.value == null) { 
                control.setErrors({ required: true });
                return;
            }

            if(matchingControl.value == null){
                matchingControl.setErrors({ required: true });
                return;
            }
    
            if (matchingControl.errors && !matchingControl.errors.dateCompare) {
                // return if another validator has already found an error on the matchingControl
                return;
            }
    
            // set error on control if validation fails
            if (control.value > matchingControl.value) {
                control.setErrors({ dateCompare: true });
            } else {
                control.setErrors(null);
            }
        }
    }

    getDeviceType(productCode: string){
        let deviceType = '';
        switch(productCode.toUpperCase()){
            case "NGX-200" : 
                deviceType = "NexxGarage";
                break;
            case "NXPG-100" : 
                deviceType = "NexxPlug";
                break;
            case "NSX-100" : 
                deviceType = "NexxSocket";
                break;
            case "NEX-100" : 
                deviceType = "NexxEye";
                break;
            case "NSWX-100" : 
                deviceType = "NexxSwitch";
                break;
            case "NGX-100" : 
                deviceType = "NexxGarage";
                break;
        }
        return deviceType
    }

    getDeviceIcon(productCode: string, status:number){
        let deviceType = this.getDeviceType(productCode);
        let deviceIcon = '';
        if(deviceType == 'NexxGarage'){
            switch(status){
                case 0:
                    deviceIcon = "garage-state-unknown.png";
                    break;
                case 1:
                    deviceIcon = "garage-open.png";
                    break;
                case 2:
                    deviceIcon = "garage-open.png";
                    break;
            }
        }else if(deviceType == 'NexxPlug'){
            switch(status){
                case 0:
                    deviceIcon = "power_on.png";
                    break;
                case 1:
                    deviceIcon = "power_off.png";
                    break;
            }
        }
        return deviceIcon;
    }

}