import { Component, OnInit, Inject } from '@angular/core';
import { AppConfig, APP_CONFIG } from '../app.config';
import { Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { AlertService } from '../service/alert.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  newUser = { name: '', email_address: '', platform: 'website' };
  confirmPwd = '';
  ferror = { name: false, email: false, inValidEmail: false };

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {

  }

  validate(): boolean {
    this.ferror = { name: false, email: false, inValidEmail: false };
    if (!this.newUser.name) {
      this.ferror.name = true;
    }

    if (!this.newUser.email_address) {
      this.ferror.email = true;
    } else if (!this.config.emailPattern.test(this.newUser.email_address)) {
      this.ferror.inValidEmail = true;
    }

    // if (!this.newUser.password) {
    //   this.ferror.password = true;
    // } else if (!(this.confirmPwd && this.confirmPwd === this.newUser.password)) {
    //   this.ferror.confirmPwd = true;
    // }

    const eKeys = Object.keys(this.ferror);
    const isValid = eKeys.every((ek) => this.ferror[ek] == false);
    return isValid;
  }

  onRegister() {
    if (!this.validate()) return;
    this.userService.register(this.newUser).subscribe((resp) => {
      this.newUser = { name: '', email_address: '', platform: 'website' };
      this.alertService.success(resp.message || 'Account created successfully', true);
      setTimeout(() => {
        document.location.href = '/';
      }, 2000);
    }, (err) => this.alertService.error(err));
  }

  onCancel() {
    this.router.navigate(['/']);
  }
}
