<div class="position-relative">
    <div class="top-section">
        <img class="float-right" src="/assets/logos/triangle group.png">
    </div>
    <div class="content-section">
        <div class="sec-mobile d-flex justify-content-around form-group">
            <div class="mr-3 pt-2">
                <img src="/assets/logos/Vector.png" alt="My.Win logo" class="img-fluid no-blurry">
            </div>
            <div class="flex-grow-1">
                <span style="font-size: 40px">Welcome to <strong>MyWin</strong></span>
                <p class="sub-txt">
                    Here you can access a variety of features and functionalities, as well as a wealth of industry
                    information and insights to impact your business outcomes.
                </p>
            </div>
        </div>
        <div class="links-content">
            <div class="form-group page-title">
                myLearning
            </div>
            <div class="row row-eq-height nav-module-section mt-3 form-group">
                <div class="col-md-4 form-group">
                    <div class="nav-module" (click)="onMyLearningClick('/individual/knowledge-series')">
                        <div class="mx-2 position-relative">
                            <img src="/assets/logos/icon_knowledge_series.png" class="no-blurry" width="74" height="64">
                        </div>
                        <div class="d-flex">
                            <div>
                                <div class="mb-2"
                                    style="font-size: 20px;padding-top: 3%;font-family: 'Proxima-Nova-Bold';">
                                    <strong>Knowledge Series</strong>
                                </div>
                                <p class="font-size-13">
                                    Library of videos relevant and useful to you for product development, process
                                    design, manufacturing, automation, food safety, etc.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 form-group">
                    <div class="nav-module" (click)="onMyLearningClick('/individual/insights')">
                        <div class="mx-2 position-relative">
                            <img src="/assets/logos/icon_insights.png" class="no-blurry" width="74" height="64">
                        </div>
                        <div class="d-flex">
                            <div>
                                <div class="mb-2"
                                    style="font-size: 20px;padding-top: 3%;font-family: 'Proxima-Nova-Bold';">
                                    <strong>Insights</strong>
                                </div>
                                <p class="font-size-13">
                                    Useful insights into various segments, curated specifically for food industry
                                    professionals like you.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4 form-group">
                    <div class="nav-module" (click)="onMyLearningClick('fut-ure')">
                        <div class="mx-2 position-relative">
                            <img src="/assets/logos/icon_future.png" class="no-blurry" width="74" height="64">
                        </div>
                        <div class="d-flex">
                            <div>
                                <div class="mb-2"
                                    style="font-size: 20px;padding-top: 3%;font-family: 'Proxima-Nova-Bold';">
                                    <strong>Fut.ure</strong>
                                </div>
                                <p class="font-size-13">
                                    An invite-only virtual roundtable to bring the Food Manufacturers, Technology
                                    Solutions Providers and food industry communities together to discuss the challenges
                                    facing the industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- <div class="form-group page-title mt-2">
                myCommunity
            </div> -->
            <div class="row row-eq-height nav-module-section mt-3">
                <!-- <div class="col-md-8">
                    <div class="nav-module" (click)="onMyLearningClick('fut-ure-mar-21')">
                        <div class="d-flex">
                            <div class="mx-2 position-relative">
                                <img src="/assets/logos/icon_future.png" class="no-blurry" width="74" height="64">
                            </div>
                            <div>
                                <div class="mb-2" style="font-size: 20px;font-family: 'Proxima-Nova-Bold';">
                                    <strong>Fut.ure</strong>
                                </div>
                                <p class="font-size-13">
                                    An invite-only virtual roundtable to bring the Food Manufacturers, Technology
                                    Solutions Providers and food industry communities together to discuss the challenges
                                    facing the industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div *ngIf="existing_domain" class="col-md-4">
                    <div *ngIf="!request_sent" class="nav-module" style="background: #D0F2FF;">
                        <div class="d-flex">
                            <div>
                                <div style="font-size: 20px;padding-top: 3%;font-family: 'Proxima-Nova-Bold';">
                                    <strong>Your organisation account</strong>
                                </div>
                                <p class="font-size-13">
                                    Send a request and on approval you can use the My.Win app to the full extent
                                </p>
                                <div>
                                    <button *ngIf="requesting" class="btn btn-success" disabled>
                                        <span class="fa fa-circle-o-notch fa-spin fa-spin mr-1"></span> Sending
                                    </button>
                                    <button *ngIf="!requesting" class="btn btn-success" (click)="onSendRequest()">Send
                                        Request</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="request_sent" class="nav-module" style="background: #C8FACD;">
                        <div class="d-flex">
                            <div>
                                <div style="font-size: 20px;padding-top: 3%;font-family: 'Proxima-Nova-Bold';">
                                    <strong>Request Sent!</strong>
                                </div>
                                <p class="font-size-13">
                                    We have sent a request. Once the admin approves you will receive an email to relogin
                                    and you can start using the organization account.
                                </p>
                                <div>
                                    <button class="btn btn-success" disabled>Request Sent</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!existing_domain" class="demo-panel">
    <div class="demo-panel-head">
        <div class="form-group">
            <span style="font-size: 24px;">My.Win Subscription</span>
        </div>
        <div class="d-flex text-center mx-4 border round-16 user-tabs">
            <div class="flex-fill py-2 hand" [class.active-tab]="user_type" (click)="user_type = true">
                For OEMs
            </div>
            <div class="flex-fill py-2 hand" [class.active-tab]="!user_type" (click)="user_type = false">
                For FMs
            </div>
        </div>
    </div>
    <div class="demo-panel-body">
        <ng-container *ngIf="user_type">
            <div class="d-flex">
                <div class="mx-2 position-relative">
                    <img src="/assets/logos/icon_solution_2x.png" class="no-blurry" width="36" height="32">
                </div>
                <div>
                    <div class="mb-2 demo-panel-sub-head" style="color: #328BCB;">
                        <strong>mySolutions</strong>
                    </div>
                    <p class="font-size-12">
                        Organize all the tools that the sales team would ever need
                    </p>
                </div>
            </div>
            <div class="d-flex">
                <div class="mx-2 position-relative">
                    <img src="/assets/logos/icon_opportunity_2x.png" class="no-blurry" width="36" height="32">
                </div>
                <div>
                    <div class="mb-2 demo-panel-sub-head" style="color: #D94080;">
                        <strong>myOpportunities</strong>
                    </div>
                    <p class="font-size-12">
                        Systematically go after the right opportunities with our playbook
                    </p>
                </div>
            </div>
            <div class="d-flex">
                <div class="mx-2 position-relative">
                    <img src="/assets/logos/icon_projects_2x.png" class="no-blurry" width="36" height="32">
                </div>
                <div>
                    <div class="mb-2 demo-panel-sub-head" style="color: #248480;">
                        <strong>myProjects</strong>
                    </div>
                    <p class="font-size-12">
                        Keep your customers engaged and informed on all projects
                    </p>
                </div>
            </div>
            <div class="d-flex">
                <div class="mx-2 position-relative">
                    <img src="/assets/logos/icon_installation_2x.png" class="no-blurry" width="36" height="32">
                </div>
                <div>
                    <div class="mb-2 demo-panel-sub-head" style="color: #D94080;">
                        <strong>myInstallations</strong>
                    </div>
                    <p class="font-size-12">
                        Delight your customers with an excellent digital experience
                    </p>
                </div>
            </div>
            <div class="d-flex">
                <div class="mx-2 position-relative">
                    <img src="/assets/logos/icon_partners_2x.png" class="no-blurry" width="36" height="32">
                </div>
                <div>
                    <div class="mb-2 demo-panel-sub-head" style="color: #248480;">
                        <strong>myPartners</strong>
                    </div>
                    <p class="font-size-12">
                        Grow your business globally by effectively collaborating with your resellers
                    </p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!user_type">
            <div class="d-flex">
                <div class="mx-2 position-relative">
                    <img src="/assets/logos/icon_projects_2x.png" class="no-blurry" width="36" height="32">
                </div>
                <div>
                    <div class="mb-2 demo-panel-sub-head" style="color: #248480;">
                        <strong>myProjects</strong>
                    </div>
                    <p class="font-size-12">
                        Keep your customers engaged and informed on all projects
                    </p>
                </div>
            </div>
            <div class="d-flex">
                <div class="mx-2 position-relative">
                    <img src="/assets/logos/icon_installation_2x.png" class="no-blurry" width="36" height="32">
                </div>
                <div>
                    <div class="mb-2 demo-panel-sub-head" style="color: #D94080;">
                        <strong>myInstallations</strong>
                    </div>
                    <p class="font-size-12">
                        Delight your customers with an excellent digital experience
                    </p>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="demo-panel-footer">
        <button *ngIf="requesting" class="btn btn-success" disabled>
            <span class="fa fa-circle-o-notch fa-spin fa-spin mr-1"></span> Requesting
        </button>
        <ng-container *ngIf="!requesting">
            <button *ngIf="!request_sent" class="btn btn-success btn-block"
                (click)="onRequestDemo(request_demo_form_template)">Request a
                demo</button>
            <button *ngIf="request_sent" class="btn btn-success btn-block" disabled>Request Sent</button>
        </ng-container>
    </div>
</div>


<ng-template #request_demo_form_template>
    <div class="modal-header">
        <h4 class="modal-title pull-left page-title">Request a demo</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Company name <span class="text-danger">*</span></label>
            <input type="text" class="form-control" placeholder="Enter" [(ngModel)]="request_demo_form.company_name"
                [class.input-error]="request_demo_form_error.company_name">
            <div class="text-danger fs-085" *ngIf="request_demo_form_error.company_name">
                Company name is required
            </div>
        </div>
        <div class="form-group">
            <div class="input-group mb-2 row no-gutters">
                <div class="col-3">
                    <label>Country code <span class="text-danger">*</span></label>
                    <app-ew-select [options]="countries" optionValue="phone_code" optionText="phone_code"
                        [(ngModel)]="request_demo_form.country_code">
                    </app-ew-select>
                </div>
                <div class="col-9">
                    <label>Company contact number <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Enter"
                        [(ngModel)]="request_demo_form.company_contact"
                        [class.input-error]="request_demo_form_error.company_contact || request_demo_form_error.invalid_contact">
                </div>
            </div>
            <div class="text-danger fs-085" *ngIf="request_demo_form_error.country_code">
                Country code is required
            </div>
            <div class="text-danger fs-085" *ngIf="request_demo_form_error.company_contact">
                Company contact number is required
            </div>
            <div class="text-danger fs-085" *ngIf="request_demo_form_error.invalid_contact">
                Please enter a valid contact number
            </div>
        </div>
        <div class="form-group">
            <label>Company email address <span class="text-danger">*</span></label>
            <input type="text" class="form-control" placeholder="Enter" [(ngModel)]="request_demo_form.company_email"
                [class.input-error]="request_demo_form_error.company_email || request_demo_form_error.invalid_email">
            <div class="text-danger fs-085" *ngIf="request_demo_form_error.company_email">
                Company email address is required
            </div>
            <div class="text-danger fs-085" *ngIf="request_demo_form_error.invalid_email">
                Please enter a valid email address
            </div>
        </div>
        <div class="">
            <button *ngIf="requesting" class="btn btn-success" disabled>
                <span class="fa fa-circle-o-notch fa-spin fa-spin mr-1"></span> Sending
            </button>
            <button *ngIf="!requesting" class="btn btn-success" (click)="onSendRequest()">Send Request</button>
        </div>
    </div>
</ng-template>