<div>
    <div class="multi-select-box" [class.view-mode-head]="_read_only">
        <div class="multi-select-head" (click)="isShowOptionsBody = !isShowOptionsBody">
            <!-- <span class="fa fa-chevron-down d-arrow" (click)="isShowOptionsBody = !isShowOptionsBody"></span> -->
            <img *ngIf="!_read_only" src="/assets/icons/down_arrow.svg" alt="down-arrow" class="drop-down-arrow">
            <div class="selected-item" *ngFor="let item of selectedItems">
                <ng-container *ngIf="!_optionText1">
                    {{item[_optionText]}}
                    <span *ngIf="!_read_only" class="fa fa-times text-secondary ml-2"
                        (click)="removeSelectedItem(item)">
                    </span>
                </ng-container>
                <div *ngIf="_optionText1" class="d-flex">
                    <div>
                        <div>{{item[_optionText]}}</div>
                        {{item[_optionText1]}}
                    </div>
                    <div *ngIf="!_read_only" class="fa fa-times text-secondary ml-2 align-self-center"
                        (click)="removeSelectedItem(item)">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isShowOptionsBody && !_read_only" class="multi-select-body">
            <div *ngIf="_options.length > 7" class="search-box">
                <img src="/assets/icons/search-left.svg" alt="search" class="search-icon">
                <input type="text" class="form-control form-control-sm mb-1" placeholder="Search"
                    [(ngModel)]="searchText">
            </div>
            <div class="multi-select-list pt-1">
                <div class="multi-select-list-item text-truncate" title="Select All" (click)="onSelectAllToggle($event)">
                    <span *ngIf="!select_all" class="fa fa-square-o fa-lg text-secondary"></span>
                    <span *ngIf="select_all" class="fa fa-check-square fa-lg text-primary"></span>
                    <span class="ml-2">Select All</span>
                </div>
                <div class="multi-select-list-item text-truncate" [title]="option[_optionText]"
                    *ngFor="let option of _options | filter:searchText:'name'"
                    (click)="onItemSelectToggle($event,option)">
                    <span *ngIf="!isItemSelected(option[_optionValue])"
                        class="fa fa-square-o fa-lg text-secondary"></span>
                    <span *ngIf="isItemSelected(option[_optionValue])"
                        class="fa fa-check-square fa-lg text-primary"></span>
                    <span class="ml-2">{{option[_optionText]}}</span>
                    <span *ngIf="_optionText1" class="text-light1 float-right">{{option[_optionText1]}}</span>
                </div>
                <div *ngIf="_options.length === 0" class="sub-title2 py-2 text-center">
                    {{ _helpText }}
                </div>
                <!-- <div *ngIf="_options.length === 0" class="sub-title2 py-2 text-center">
                    No Result Found
                </div> -->
            </div>
        </div>
    </div>
</div>