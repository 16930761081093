<div class="p-3">
    <div>
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
            [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3" [resizeToWidth]="256"
            [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="isRoundCropper"
            [canvasRotation]="canvasRotation" format="png" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </div>
    <img [src]="croppedImage | safe" />
</div>