import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { AlertComponent } from 'ngx-bootstrap';

@Injectable({
  providedIn : "root"
})
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router, private modalService: NgbModal) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    confirm(message: string, title: string){
        this.keepAfterNavigationChange = true;
        this.subject.next({ type: 'confirm', text: message, title: title});    
    }

    confimrDialog(message: string, title: string): Promise<boolean> {
        const modalRef = this.modalService.open(DialogComponent);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.type = "confirm";
        // modalRef.componentInstance.btnOkText = "Yes";
        // modalRef.componentInstance.btnCancelText = "Cancle";
    
        return modalRef.result;
      }
    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
    }

    warning(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'warning', text: message });
    }

    error(message: string, keepAfterNavigationChange = false) {
        if(!message) return;
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
    }

    sendParam(param:any){
        this.subject.next({ type: 'param', text: param });
    }

    getMessage(): Observable<any> {
       return this.subject.asObservable();
    }
}
