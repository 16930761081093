<div class="container-fluid">
    <div class="row my-3">
        <div *ngIf="!showChangePwd" class="col-md-5 offset-md-3">
            <div *ngIf="userProfile">
                <div class="image-row">
                    <div class="text-center">

                    </div>
                    <div *ngIf="userProfile.user_type != 'Individual'" class="text-center">
                    </div>
                </div>
                <div class="form-group row justify-content-center">
                    <div class="col-md-3">
                        <div class="profile-img">
                            <img *ngIf="userProfile?.user_logo || userProfile?.user_logo_blob"
                                [src]="(userProfile?.user_logo_blob | ewurl | async) || (userProfile.user_logo | safe)"
                                alt="profile picture" class="no-blurry">
                            <img *ngIf="!(userProfile?.user_logo || userProfile?.user_logo_blob)"
                                src="/assets/img/sampleImage.png" alt="profile picture">
                            <span class="fa fa-pencil ml-2 text-secondary"></span>
                            <input type="file" (change)="onFileChanged($event)">
                        </div>
                        <p>Profile Picture</p>
                    </div>
                    <div *ngIf="userProfile.user_type != 'Individual'" class="col-md-3">
                        <div class="profile-img">
                            <img *ngIf="userProfile?.company_logo || userProfile?.company_logo_blob"
                                [src]="(userProfile?.company_logo_blob | ewurl | async) || (userProfile.company_logo | safe)"
                                alt="company logo" class="no-blurry">
                            <img *ngIf="!userProfile?.company_logo" src="/assets/img/default-comp-logo.png"
                                alt="company logo">
                        </div>
                        <p>Company Logo</p>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-5 col-form-label text-secondary">
                        <strong class="float-md-right">Name : </strong>
                    </label>
                    <div class="col-md-7">
                        <input type="text" class="form-control" [value]="userProfile.name" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-5 col-form-label text-secondary">
                        <strong class="float-md-right">Email : </strong>
                    </label>
                    <div class="col-md-7">
                        <input type="text" readonly class="form-control" [value]="userProfile.email">
                    </div>
                </div>
                <div *ngIf="userProfile.user_type != 'Individual'" class="form-group row">
                    <label class="col-md-5 col-form-label text-secondary">
                        <strong class="float-md-right">Company Name : </strong>
                    </label>
                    <div class="col-md-7">
                        <input type="text" readonly class="form-control" [value]="userProfile.company_name">
                    </div>
                </div>
                <div *ngIf="userProfile.user_type == 'OEM'" class="form-group row">
                    <label class="col-md-5 col-form-label text-secondary">
                        <strong class="float-md-right">Subscription : </strong>
                    </label>
                    <div class="col-md-7">
                        <input type="text" class="form-control" [value]="userProfile.subscription_name" readonly>
                    </div>
                </div>
                <div *ngIf="userProfile.user_type != 'Individual'" class="form-group row">
                    <label class="col-md-5 col-form-label text-secondary">
                        <strong class="float-md-right">Role : </strong>
                    </label>
                    <div class="col-md-7">
                        <input type="text" class="form-control" [value]="userProfile.role" readonly>
                    </div>
                </div>
                <div *ngIf="userProfile.user_type != 'Individual'" class="form-group row">
                    <label class="col-md-5 col-form-label text-secondary">
                        <strong class="float-md-right">Designation : </strong>
                    </label>
                    <div class="col-md-7">
                        <input type="text" class="form-control" [value]="userProfile.designation" readonly>
                    </div>
                </div>
                <div *ngIf="userProfile.user_type != 'Individual'" class="form-group row">
                    <label class="col-md-5 col-form-label text-secondary">
                        <strong class="float-md-right">Country : </strong>
                    </label>
                    <div class="col-md-7">
                        <select class="form-control" [value]="userProfile.location" disabled>
                            <option value="''">Select Country</option>
                            <option [value]="country.alpha3" *ngFor="let country of countries">{{country.name}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="userProfile.user_type != 'Individual'" class="form-group row">
                    <label class="col-md-5 col-form-label text-secondary">
                        <strong class="float-md-right">Phone Number : </strong>
                    </label>
                    <div class="col-md-7">
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{selctedPhoneCode}}</span>
                            </div>
                            <input type="text" class="form-control bor-input" [value]="userProfile.contact_number"
                                readonly>
                        </div>
                    </div>
                </div>
                <div class="clearfix row">
                    <div class="col-md-6">
                        <button type="button" class="btn btn-link float-left" (click)="onShowChangePwd()">
                            <span class="fa fa-key mr-1"></span> Change Password</button>
                    </div>
                    <div class="col-md-6">
                        <button *ngIf="userProfile.user_type == 'Admin'" type="button"
                            class="btn btn-success w-80 float-right" (click)="onSaveDetails()">Save</button>
                        <button type="button" class="btn btn-outline-secondary w-80 float-right mr-2"
                            (click)="onCancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showChangePwd" class="col-md-3 offset-md-4">
            <div class="form-group">
                <label>Current Password :</label>
                <input type="password" class="form-control" [(ngModel)]="chagenPwdModel.current_password"
                    [class.input-error]="ferror.current_password">
                <div *ngIf="ferror.current_password" class="text-danger fs-085">
                    Current password required
                </div>
            </div>
            <div class="form-group">
                <label>New Password :</label>
                <input type="password" class="form-control" [(ngModel)]="chagenPwdModel.new_password"
                    [class.input-error]="ferror.new_password">
                <div *ngIf="ferror.new_password" class="text-danger fs-085">
                    New password required
                </div>
            </div>
            <div class="form-group">
                <label>Confirm New Password :</label>
                <input type="password" class="form-control" [(ngModel)]="chagenPwdModel.confirm_password"
                    [class.input-error]="ferror.confirm_password || ferror.isPwdNotMatch">
                <div *ngIf="ferror.confirm_password" class="text-danger fs-085">
                    Confirm password required
                </div>
                <div *ngIf="ferror.isPwdNotMatch" class="text-danger fs-085">
                    Password does not match
                </div>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-primary btn-block" (click)="onChangePassword()">Submit</button>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-outline-secondary btn-block"
                    (click)="onCancelChangePassword()">Cancel</button>
            </div>
        </div>
    </div>
</div>