import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication.service';
@Component({
  selector: 'app-individual-profile',
  templateUrl: './individual-profile.component.html',
  styleUrls: ['./individual-profile.component.css']
})
export class IndividualProfileComponent implements OnInit {

  constructor(public authService: AuthenticationService,) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
    // this.router.navigate(['/login']);
  }

}
