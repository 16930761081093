<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="border round-8 p-1 d-flex align-items-center justify-content-center border">
        <img style="max-width: 100%;max-height: 100%;" [src]="data" [alt]="alt_label_text" class="no-blurry">
    </div>
</div>