<ng-template #userProfile>
    <div>
        <div class="mb-2">

            <span class="fa fa-user-circle ml-5 mt-2 lg-dropdown"></span>
            <span class="ligt-link" (click)="onProfileLinkClick()">View Profile</span>
        </div>
        <div (click)="logout()">
            <span class="fa fa-sign-out ml-5 mt-2 lg-dropdown"></span>
            <span class="ligt-link">Logout</span>

        </div>
    </div>
</ng-template>


<!-- <div *ngIf="toggleSideMenu" class="admin-side-menu">

    <div class="logo-size">
        <div *ngIf="toggleSideMenu" class="d-inline-block hand text-black cross-side"
            (click)="toggleSideMenu = !toggleSideMenu">
            <span class="fa fa-times fa-3x"></span>
        </div>
        <img src="/assets/svg-icon/logo.svg" alt="My.Win logo" class="img-fluid no-blurry" (click)="gotoEWDashboard()">


    </div>
    <div class="menu-item" *ngFor="let link of links"
        [class.active-menu-item]="selectedLink && link.name == selectedLink.name">
        <div class="child-links-wrap">
            <div class="py-3" (click)="onChildLinkClick(child)" *ngFor="let child of link.children">
                {{child.name}}
            </div>
        </div>
        <div class="link-item-wrap" (click)="onLinkClick(link)"
            [class.active-link]="selectedLink && link.name == selectedLink.name">
            <img src='{{"/assets/svg-icon/"+link.f_icon}}' class="img-fluid no-blurry icon-size">
            <span class="link-name">{{link.name}}</span>
        </div>
    </div> 
    <hr class="profile-bottom-hr">
    <div class="profile-bottom">
        <div>
            <img *ngIf="currentUser?.user_logo" [src]="currentUser?.user_logo" alt="Avatar" class="user-avatar">
            <img *ngIf="!currentUser?.user_logo" src="/assets/img/sampleImage.png" alt="Avatar" class="user-avatar">
            <span [popover]="userProfile" placement="right" [outsideClick]="true">
                <span class="fa fa-angle-right d-arrow1"></span>
            </span>
        </div>
        <span class="px-2 user-txt">{{fullname}}</span>

        <img [style.opacity]="currentUser?.company_logo ? 1 : 0" [src]="currentUser?.company_logo" alt="Avatar"
            class="company-logo no-blurry">
    </div>
</div> -->
<div class="admin-body ml-0 w-100" [class.full-body]="!toggleSideMenu">

    <!-- <div class="admin-top-bar">
        <div *ngIf="!toggleSideMenu" class="d-inline-block hand" (click)="toggleSideMenu = !toggleSideMenu">
            <span class="fa fa-bars fa-iconsize"></span>
        </div>
        <div class="d-inline-block">
            <h3 class="ew-head-font text-white">{{this.selectedLink?.name | uppercase}} <span
                    *ngIf="this.selectedChildLink?.name"> -
                    {{this.selectedChildLink?.name | uppercase}}</span></h3>
        </div>
    </div> -->
    <div class="p-0">
        <div class="position-relative">
            <div class="top-section" style="height: 50vh !important;">
                <img class="float-right" src="/assets/logos/triangle group.png">
            </div>
            <div class="content-section">
                <div class="d-flex flex-sm-row flex-column">
                    <div class="pt-1" style="margin-right: 10px;">
                        <img src="/assets/logos/mywin_logo.svg" alt="My.Win logo" class="img-fluid no-blurry"
                            style="height: 48px !important;">
                    </div>
                    <div class="flex-grow-1">
                        <span style="font-size: 40px">Welcome to <strong>My.Win</strong></span>
                        <p style="font-size: 16px;width: 60%;">
                            Here you can access a variety of features and functionalities, as well as a wealth of
                            industry
                            information and insights to impact your business outcomes.
                        </p>
                    </div>
                </div>
                <div class="row row-eq-height nav-module-section mt-3">
                    <div class="col">
                        <h4 class="main-title">OEM</h4>
                        <div class="nav-module mt-3" [routerLink]="['/oem/solutions']">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_solution_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #328BCB;">
                                    <strong>mySolutions</strong>
                                </div>
                            </div>
                        </div>

                        <div class="nav-module mt-3" [routerLink]="['/oem/opportunity']">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_opportunity_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #D94080;">
                                    <strong>myOpportunities</strong>
                                </div>
                            </div>
                        </div>

                        <div class="nav-module mt-3" [routerLink]="['/oem/projects']">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_projects_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #248480;">
                                    <strong>myProjects</strong>
                                </div>
                            </div>
                        </div>

                        <div class="nav-module mt-3" [routerLink]="['/oem/tagged-equipments']">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_installation_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #D94080;">
                                    <strong>myInstallations</strong>
                                </div>
                            </div>
                        </div>

                        <div class="nav-module mt-3" [routerLink]="['/oem/partners']">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_partners_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #248480;">
                                    <strong>myPartners</strong>
                                </div>
                            </div>
                        </div>

                        <div class="nav-module mt-3" [routerLink]="['/oem/my-learning']">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_learning_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #EBB63F;">
                                    <strong>myLearning</strong>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col" style="display: none;">
                        <h4 class="main-title">Reseller</h4>
                        <div class="nav-module mt-3">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_solution_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #328BCB;">
                                    <strong>mySolutions</strong>
                                </div>
                            </div>
                        </div>

                        <div class="nav-module mt-3">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_opportunity_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #D94080;">
                                    <strong>myOpportunities</strong>
                                </div>
                            </div>
                        </div>

                        <div class="nav-module mt-3">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_projects_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #248480;">
                                    <strong>myProjects</strong>
                                </div>
                            </div>
                        </div>

                        <div class="nav-module mt-3">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_installation_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #D94080;">
                                    <strong>myInstallations</strong>
                                </div>
                            </div>
                        </div>

                        <div class="nav-module mt-3">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_partners_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #248480;">
                                    <strong>myPartners</strong>
                                </div>
                            </div>
                        </div>

                        <div class="nav-module mt-3" (click)="onMyLearningClick()">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_learning_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #EBB63F;">
                                    <strong>myLearning</strong>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <h4 class="main-title">FM</h4>
                        <div class="nav-module mt-3" [routerLink]="['/customer/customer-projects']">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_projects_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #248480;">
                                    <strong>myProjects</strong>
                                </div>
                            </div>
                        </div>
                        <div class="nav-module mt-3" [routerLink]="['/customer/customer-installations']">
                            <div class="d-flex flex-sm-row flex-column">
                                <div class="align-self-center ml-2">
                                    <img class="img-size" src="/assets/logos/icon_installation_2x.png" class="no-blurry" width="74" height="64">
                                </div>
                                <div class="align-self-center ml-2" style="font-size: 20px; color: #D94080;">
                                    <strong>myInstallations</strong>
                                </div>
                            </div>
                        </div>
                    </div>



                    <!-- <div class="col-md-4 mb-4" [routerLink]="['solutions']">
                        <div class="nav-module">
                            <div class="d-flex">
                                <div class="mx-2 position-relative">
                                    <img src="/assets/logos/icon solution.png">
                                </div>
                                <div>
                                    <div class="mb-2" style="font-size: 20px; color: #328BCB;padding-top: 7%;">
                                        <strong>mySolutions</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" [routerLink]="['opportunity']">
                        <div class="nav-module">
                            <div class="d-flex">
                                <div class="mx-2 position-relative">
                                    <img src="/assets/logos/icon opportunity.png">
                                </div>
                                <div>
                                    <div class="mb-2" style="font-size: 20px; color: #D94080;padding-top: 7%;">
                                        <strong>myOpportunities</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="nav-module">
                            <div class="d-flex">
                                <div class="mx-2 position-relative">
                                    <img src="/assets/logos/icon projects.png">
                                </div>
                                <div>
                                    <div class="mb-2" style="font-size: 20px; color: #248480;padding-top: 7%;">
                                        <strong>myProjects</strong>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" [routerLink]="['tagged-equipments']">
                        <div class="nav-module">
                            <div class="d-flex">
                                <div class="mx-2 position-relative">
                                    <img src="/assets/logos/icon installation.png">
                                </div>
                                <div>
                                    <div class="mb-2" style="font-size: 20px; color: #D94080;padding-top: 7%;">
                                        <strong>myInstallations</strong>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="nav-module">
                            <div class="d-flex">
                                <div class="mx-2 position-relative">
                                    <img src="/assets/logos/icon partners.png">
                                </div>
                                <div>
                                    <div class="mb-2" style="font-size: 20px; color: #248480;padding-top: 7%;">
                                        <strong>myPartners</strong>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="nav-module" (click)="onMyLearningClick()">
                            <div class="d-flex">
                                <div class="mx-2 position-relative">
                                    <img src="/assets/logos/icon learning.png">
                                </div>
                                <div>
                                    <div class="mb-2" style="font-size: 20px; color: #EBB63F;padding-top: 7%;">
                                        <strong>myLearning</strong>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="fs-085 text-secondary mt-3">2022 MY.WIN. All rights reserved</div>
            </div>
        </div>

    </div>
    <router-outlet></router-outlet>
    <div *ngIf="show_my_profile">
        <app-user-profile (cancel)="onCancelProfile()"></app-user-profile>
    </div>
</div>