<div class="container-fluid">
    <div class="row mt-3 form-group">
        <div class="col-md-3 offset-md-1">
            <div class="form-group">
                <span routerLink="/">
                    <img src="/assets/logos/colour_ogo/Colour Logo (transparent bg) -02.png" alt="logo" class="img-fluid no-blurry">
                  </span>
            </div>
            <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" [(ngModel)]="newUser.name" [class.input-error]="ferror.name">
                <div *ngIf="ferror.name" class="text-danger fs-085">
                    Name is required
                </div>
            </div>
            <div class="form-group">
                <label>Email Address</label>
                <input type="text" class="form-control" autocomplete="new-password" [(ngModel)]="newUser.email_address" [class.input-error]="ferror.email || ferror.inValidEmail">
                <div *ngIf="ferror.name" class="text-danger fs-085">
                    Email is required
                </div>
                <div *ngIf="ferror.inValidEmail" class="text-danger fs-085">
                    Enter a valid email address
                </div>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-primary btn-block" (click)="onRegister()">Register</button>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-secondary btn-block" (click)="onCancel()">Cancel</button>
            </div>
        </div>
        <div class="col-md-8 text-center">
            <img src="/assets/img/loginImage.jpg" class="img-fluid" alt="My_Win-image">
        </div>
    </div>
</div>