import { Component, OnInit, Inject } from '@angular/core';
import { AppConfig, APP_CONFIG } from '../app.config';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { AlertService } from '../service/alert.service';
import { Business } from '../service/Business.service';
import { AuthenticationService } from '../service/authentication.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  countries = [];
  companyNames = [];
  suggestCompanies = [];
  user = {
    company_name: '', company_logo: '', contact_number: '', designation: '', country: '', hq_country: '', website: '',
    user_logo: ''
  };
  ferror = {
    company_name: false, company_logo: false, contact_number: false, designation: false, country: false, hq_country: false,
    website: false, invalidWebsite: false, invalidPhone: false
  };
  showRegistrationForm = false;
  selectedCompany = '';
  selctedPhoneCode = '';

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private businessService: Business,
    private authService: AuthenticationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const username = params['username'];
      const token = params['token'];
      if (!(username && token)) {
        const currentUser = sessionStorage.getItem('loggedInUserInfo');
        if (currentUser) {
          const token = JSON.parse(currentUser).access_token;
          this.authService.validateUser(token).subscribe((resp) => {
            this.showRegistrationForm = true;
          }, (err) => this.router.navigate(['/']));
        }
      } else {
        this.authService.login_refresh(username, token).subscribe((resp) => {
          debugger;
          if (resp.user_type == "Admin") {
            this.router.navigate(['/admin']);
          }
          if (resp.user_type == "OEM") {
            this.router.navigate(['/oem']);
          }
          if (resp.user_type == "Food Manufacturer") {
            this.router.navigate(['/customer']);
          }
          if (resp.user_type == "Individual") {
            this.router.navigate(['/welcome']);
          }
          if (resp.user_type == "My Win User") {
            this.router.navigate(['/mywin-user']);
          }
        }, (err) => {
          this.alertService.error(err, true);
          this.router.navigate(['/']);
        });
      }
    })
    this.getCompaniesList();
    this.getCountries();
  }

  getCountries(): void {
    this.businessService.getAllCountries().subscribe((data) => {
      this.countries = data;
    }, (err) => this.alertService.error(err));
  }

  getCompaniesList() {
    this.businessService.getRegisteredCompaniesList().subscribe((data) => {
      this.companyNames = data.oem_list;
    }, (err) => this.alertService.error(err));
  }

  validate(): boolean {
    this.ferror = {
      company_name: false, company_logo: false, contact_number: false, designation: false, country: false, hq_country: false,
      website: false, invalidWebsite: false, invalidPhone: false
    };

    if (!this.user.company_name) {
      this.ferror.company_name = true;
    }

    if(!this.selectedCompany) {
      if (!this.user.company_logo) {
        this.ferror.company_logo = true;
      }
      if (!this.user.hq_country) {
        this.ferror.hq_country = true;
      }
      if (!this.user.website) {
        this.ferror.website = true;
      } else if (!this.config.websiteUrlPattern.test(this.user.website)) {
        this.ferror.invalidWebsite = true;
      }
    }

    if (!this.user.designation) {
      this.ferror.designation = true;
    }
    if (!this.user.contact_number) {
      this.ferror.contact_number = true;
    } else if (!this.config.phoneNumberPattern.test(this.user.contact_number)) {
      this.ferror.invalidPhone = true;
    }
    if (!this.user.country) {
      this.ferror.country = true;
    }

    const eKeys = Object.keys(this.ferror);
    const isValid = eKeys.every((ek) => this.ferror[ek] == false);
    return isValid;
  }

  onSubscribe() {
    if (!this.validate()) return;
    this.user.contact_number = this.selctedPhoneCode + ' ' + this.user.contact_number;
    this.userService.subscribe(this.user).subscribe((resp) => {
      this.alertService.success(resp.message || 'Subscribed successfully', true);
      this.authService.currentUserSubject.subscribe((profile) => {
        let _profile: any = profile;
        _profile.show_subscribe_button = false;
        this.authService.setCurrentUserDetails(_profile);
        this.router.navigate(['/welcome']);
      });
    }, (err) => this.alertService.error(err));
  }

  onCancel() {
    this.router.navigate(['/welcome']);
  }

  onCompanyKeyUp() {
    this.suggestCompanies = [];
    this.selectedCompany = '';
    if (!this.user.company_name) return;
    const filteredCompanies = this.companyNames.filter((item) => item.toLowerCase().includes(this.user.company_name.toLowerCase()));
    this.suggestCompanies = [...filteredCompanies];
    if (this.suggestCompanies.length === 1 && this.user.company_name.toLowerCase() == this.suggestCompanies[0].toLowerCase()) {
      this.selectedCompany = this.suggestCompanies[0];
    }
  }

  onSuggestItemSelect(company: string) {
    this.selectedCompany = company;
    this.user.company_name = this.selectedCompany;
    this.suggestCompanies = [];
  }

  onCountryChange(isEndUser = false) {
    this.selctedPhoneCode = '';
    const country: any = this.countries.find((item: any) => item.alpha3 == this.user.country);
    if (country && country.phone_code) {
      this.selctedPhoneCode = country.phone_code;
    }
  }
}
