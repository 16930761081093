<div class="container-fluid">
    <div *ngIf="showRegistrationForm" class="row mt-3 form-group">
        <div class="col-md-3 offset-md-1">
            <div class="form-group">
                <span routerLink="/">
                    <img src="/assets/logos/colour_ogo/Colour Logo (transparent bg) -02.png" alt="logo"
                        class="img-fluid no-blurry">
                </span>
            </div>
            <div class="form-group">
                <label>Company Name</label>
                <input type="text" class="form-control" [(ngModel)]="user.company_name" (keyup)="onCompanyKeyUp()"
                    [class.input-error]="ferror.company_name">
                <div *ngIf="ferror.company_name" class="text-danger fs-085">
                    Company Name is required
                </div>
                <div *ngIf="suggestCompanies.length > 0" class="auto-suggest-box">
                    <div *ngFor="let comp of suggestCompanies" class="suggest-item" (click)="onSuggestItemSelect(comp)">
                        {{comp}}</div>
                </div>
            </div>
            <div *ngIf="!selectedCompany" class="form-group">
                <app-ew-file-upload label='Company Logo' isBase64=true [(ngModel)]="user.company_logo">
                </app-ew-file-upload>
                <div *ngIf="ferror.company_logo" class="text-danger fs-085">
                    Company Logo is required
                </div>
            </div>
            <div class="form-group">
                <label>Designation</label>
                <input type="text" class="form-control" [(ngModel)]="user.designation"
                    [class.input-error]="ferror.designation">
                <div *ngIf="ferror.designation" class="text-danger fs-085">
                    Designation is required
                </div>
            </div>
            <div class="form-group">
                <label>Country</label>
                <select class="form-control" [(ngModel)]="user.country" (change)="onCountryChange()">
                    <option value="">Select Country</option>
                    <option [value]="country.alpha3" *ngFor="let country of countries">{{country.name}}</option>
                </select>
                <!-- <app-ew-select label="" [options]="countries" [(ngModel)]="user.country" optionValue="id"
                    optionText="name"></app-ew-select> -->
                <div *ngIf="ferror.country" class="text-danger fs-085">
                    Country is required
                </div>
            </div>
            <div class="form-group">
                <label>Contact Number</label>
                <div class="input-group input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{selctedPhoneCode}}</span>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]="user.contact_number"
                        [class.input-error]="ferror.contact_number || ferror.invalidPhone">
                </div>
                <!-- <input type="text" class="form-control" [(ngModel)]="user.contact_number"
                    [class.input-error]="ferror.contact_number || ferror.invalidPhone"> -->
                <div *ngIf="ferror.contact_number" class="text-danger fs-085">
                    Contact Number is required
                </div>
                <div *ngIf="ferror.invalidPhone" class="text-danger fs-085">
                    Enter valid contact number
                </div>
            </div>
            <div *ngIf="!selectedCompany" class="form-group">
                <label>Website</label>
                <input type="text" class="form-control" autocomplete="new-password" [(ngModel)]="user.website"
                    [class.input-error]="ferror.website || ferror.invalidWebsite">
                <div *ngIf="ferror.website" class="text-danger fs-085">
                    Website is required
                </div>
                <div *ngIf="ferror.invalidWebsite" class="text-danger fs-085">
                    Enter a valid website URL
                </div>
            </div>
            <div *ngIf="!selectedCompany" class="form-group">
                <label>HQ Country</label>
                <select class="form-control" [(ngModel)]="user.hq_country">
                    <option value="">Select HQ Country</option>
                    <option [value]="country.alpha3" *ngFor="let country of countries">{{country.name}}</option>
                </select>
                <!-- <app-ew-select label="" [options]="countries" [(ngModel)]="user.hq_country" optionValue="id"
                    optionText="name"></app-ew-select> -->
                <div *ngIf="ferror.hq_country" class="text-danger fs-085">
                    Headquarters Country is required
                </div>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-primary btn-block" (click)="onSubscribe()">Subscribe</button>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-outline-secondary btn-block" (click)="onCancel()">Cancel</button>
            </div>
        </div>
        <div class="col-md-8 text-center">
            <img src="/assets/img/loginImage.jpg" class="img-fluid" alt="My_Win-image" style="height: 100% !important">
        </div>
    </div>
</div>