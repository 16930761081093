import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { differenceInMinutes } from 'date-fns';
import { first } from 'rxjs/operators';
import { Business } from 'src/app/service/Business.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';


@Component({
  selector: 'app-user-knowledge-series-details',
  templateUrl: './user-knowledge-series-details.component.html',
  styleUrls: ['./user-knowledge-series-details.component.scss']
})
export class UserKnowledgeSeriesDetailsComponent implements OnInit {
  @Input() user_module_link;

  knowledge_series_id = null;
  knowledge_series = null;
  related_knowledge_series = [];
  tags = [];
  key_points = [];
  modalRef: BsModalRef;
  comment_form = { id: null, knowledge_series: null, content: '' };
  comments = [];
  current_user = null;

  constructor(
    private businessService: Business,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private confirmDialog: ConfirmDialogService
  ) { }

  ngOnInit(): void {
    this.current_user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.route.queryParams.subscribe((params) => {
      this.knowledge_series_id = params['id'];
      if (this.knowledge_series_id) {
        this.getKnowledgeSeriesDetails();
      }
    });
  }

  getKnowledgeSeriesDetails() {
    this.businessService.getKnowledgeSeriesDetails(this.knowledge_series_id).subscribe((resp) => {
      this.knowledge_series = resp;
      this.tags = [...resp.technologies_set.map(x => x.name), ...resp.industries_set.map(x => x.name), ...resp.products_set.map(x => x.name)];
      this.key_points = resp.key_content.split("\n");
      this.comments = this.knowledge_series.comments;
      this.setCommentEditDeletePermission()
      this.getRelatedKnowledgeSeries();
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
      this.updateView();
    });
  }

  getRelatedKnowledgeSeries() {
    this.businessService.getRelatedKnowledgeSeries(this.knowledge_series_id).subscribe((data) => {
      this.related_knowledge_series = data.slice(0, 3);
    });
  }

  updateView() {
    this.businessService.updateKnowledgeSeriesView({ knowledge_series: this.knowledge_series_id }).subscribe();
  }

  postComment() {
    if (!this.comment_form.content) return;
    this.comment_form.knowledge_series = this.knowledge_series_id;
    this.businessService.addKnowledgeSeriesComment(this.comment_form).subscribe((resp) => {
      this.comments.unshift(resp);
      this.setCommentEditDeletePermission();
      this.comment_form = { id: null, knowledge_series: null, content: '' };
    });
  }

  updateComment(comment) {
    if (!this.comment_form.content) return;
    this.comment_form.knowledge_series = this.knowledge_series_id;
    this.businessService.updateKnowledgeSeriesComment(this.comment_form).subscribe((resp) => {
      this.setCommentEditDeletePermission();
      comment.content = resp.content;
      comment.modified_by = resp.modified_by;
      comment.modified_on = resp.modified_on;
      this.comment_form = { id: null, knowledge_series: null, content: '' };
    });
  }

  onEditComment(comment) {
    this.comment_form = { ...comment };
  }

  cancelEditComment() {
    this.comment_form = { id: null, knowledge_series: null, content: '' };
  }

  deleteComment(comment) {
    this.confirmDialog.showConfirm(`Are you sure you want to delete this comment "${comment.content}"`).pipe(first()).subscribe((status) => {
      if (status) {
        this.businessService.deleteKnowledgeSeriesComment(comment.id).subscribe((resp) => {
          const delete_index = this.comments.findIndex(c => c.id == comment.id);
          this.comments.splice(delete_index, 1);
        });
      }
    });
  }

  setCommentEditDeletePermission() {
    this.comments.forEach(c => c.can_edit_delete = false);
    const user_latest_post = this.comments.find(c => c?.created_by_user_name == this.current_user.username);
    if (user_latest_post) {
      const posted_at = new Date(user_latest_post.created_on)
      const current_time = new Date();
      const minutes_difference = differenceInMinutes(current_time, posted_at);
      if (minutes_difference < 5) {
        user_latest_post.can_edit_delete = true;
      }
    }
  }

  onViewDetails(knowledge_series) {
    const nav_link = `/${this.user_module_link}/knowledge-series-details`;
    this.router.navigate([nav_link], { queryParams: { id: knowledge_series.id } });
  }

  onViewNotes(template) {
    const notes = this.knowledge_series?.notes_document_details;
    if (notes.length === 1) {
      let url = this.router.createUrlTree(['/document-view'],
        { queryParams: { blob_name: notes[0]?.blob_name } });
      window.open(this.router.serializeUrl(url), '_blank');
    } else if (notes.length > 1) {
      this.openNotesDocuments(template);
    }
  }

  openNotesDocuments(template) {
    this.modalRef = this.modalService.show(template);
  }

  onCloseNotesDocuments() {
    this.modalRef.hide();
  }

  onLinkClick(link) {
    if (this.user_module_link == 'individual') {
      link = '';
    }
    const nav_link = `/${this.user_module_link}/${link}`;
    this.router.navigate([nav_link]);
  }
}
