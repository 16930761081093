import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DocumentViewComponent } from "../document-view/document-view.component";

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    modalRef?: BsModalRef;
    private componentRef: any;

    constructor(
        private modalService: BsModalService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) { }

    openDocumentInPage(blob_name, file_name) {
        this.openDocument(blob_name, file_name);
        return;
        const initialState = {
            blob_name: blob_name
        };
        this.modalRef = this.modalService.show(DocumentViewComponent, { initialState, class: 'modal-lg' })
    }

    closeDocumentView() {
        this.modalRef.hide();
    }

    openDocument(blobName: string, fileName: string) {
        // Create component reference from the component 
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DocumentViewComponent);
        this.componentRef = componentFactory.create(this.injector);

        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(this.componentRef.hostView);

        // Set the blob name input for the component
        this.componentRef.instance.blob_name = blobName;
        this.componentRef.instance.file_name = fileName;

        // Get DOM element from component
        const domElem = (this.componentRef.hostView as any).rootNodes[0] as HTMLElement;

        // Append DOM element to the body
        document.body.appendChild(domElem);

        // Listen to close event
        this.componentRef.instance.closed.subscribe(() => {
            this.closeDocument();
        });
    }

    closeDocument() {
        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
    }
}