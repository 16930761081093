import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../model/user';
import { AuthenticationService } from '../service/authentication.service';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss']
})
export class IndividualComponent implements OnInit {
  currentUser: User;
  fullname = '';
  links = [];
  toggleSideMenu: boolean;
  selectedLink = null;
  selectedChildLink = null;
  currentUrl = '';
  assign_oem_permissions = null;
  is_my_win_user = false;

  scrWidth: any;

  mob_links = [];
  mob_extra_links = [];

  showmenu: boolean = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 1024) {
      this.toggleSideMenu = true;
    } else {
      this.toggleSideMenu = false;
    }
  }

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.links.push({
      path: '/individual', name: 'myLearning', icon: 'Learning', f_icon: 'ic_learning', children: []
    });
  }

  logout() {
    this.authenticationService.logout();
    // this.router.navigate(['/login']);
  }
  onLinkClick(link: any) {
    // if (link.name == 'myLearning' || link.name == 'my Learning') {
    //   this.onMyLearningClick();
    // }
    this.selectedChildLink = null;
    this.selectedLink = link;
    if (this.selectedLink.children.length > 0) {
      let childInd = 0;
      if (this.selectedLink.name == 'Library') {
        childInd = 3;
      }
      this.selectedChildLink = this.selectedLink.children[childInd];
    }
    this.router.navigate([link.path], { relativeTo: this.route });
  }

  onChildLinkClick(link: any) {
    this.selectedChildLink = link;
    this.router.navigate([link.path]);

  }

  onMobLinkClick(link: any) {

    if (link.name == "More") {
      this.showmenu = !this.showmenu;

    } else {
      this.showmenu = false;

    }
  }

  onProfileLinkClick() {
    if (this.is_my_win_user) {
      this.router.navigate(['/mywin-user'], { queryParams: { profile: true } });
    } else {
      this.router.navigate(['./individual-profile']);
      const link = { path: '/individual/individual-profile', name: 'View Profile', icon: 'user', f_icon: 'fa fa-user', children: [] }
      this.onLinkClick(link);
    }
  }

  onMyLearningClick() {
    let current_user = JSON.parse(sessionStorage.getItem('currentUser'));
    const url = 'https://enabling.win/my-win-dashboard?' + 'username=' + current_user.username + '&token=' + current_user.refresh_token;
    window.open(url, '_blank');
  }

}
