<div class="ew-select-wrapper">
    <div class="head text-truncate" [title]="selectedOptionText">
        <!-- <label class="label-text">{{label}}</label> -->
        <!-- <span class="fa fa-angle-down text-secondary fa-lg d-arrow"></span> -->
        <div *ngIf="!_read_only" class="selected-value" [class.hvr_show]="_hover_arrow" (click)="onHeadClick()"
            [class.no-border]="no_head_border && !_input_error" [class.input-error]="_input_error">
            <img src="/assets/icons/down_arrow.svg" alt="down-arrow" class="drop-down-arrow">
            <!-- <span *ngIf="selectedOption" class="remove-wrap" (click)="onRemoveSelection()">
                <img src="/assets/icons/remove_cross.svg" alt="remove_cross" class="remove-icon">
            </span> -->
            <!-- <span class="fa fa-chevron-down text-secondary d-arrow"></span> -->
            <span *ngIf="!selectedOption || selectedOption == 'all'">{{label}}</span>
            <span *ngIf="selectedOption && selectedOption != 'all'">{{selectedOptionText}}</span>
            <div *ngIf="selectedOption && selectedOptionText1 && _optionText1" class="text-light1 mr-3">
                {{selectedOptionText1}}
            </div>
        </div>
        <div *ngIf="_read_only" class="selected-value view-mode-head" [class.no-border]="no_head_border">
            <span *ngIf="!selectedOption || selectedOption == 'all'">{{label}}</span>
            <span *ngIf="selectedOption && selectedOption != 'all'">{{selectedOptionText}}</span>
            <div *ngIf="selectedOption && selectedOptionText1 && _optionText1" class="text-light1">
                {{selectedOptionText1}}
            </div>
        </div>
        <!-- <div [hidden]="!isShowOptionsBody" class="position-relative">
            <img src="/assets/icons/down_arrow.svg" alt="down-arrow" class="drop-down-arrow">
            <input type="text" class="form-control form-control" [(ngModel)]="searchText" #searchIn
                (keyup)="onKeyUp($event)">
        </div> -->
    </div>
    <div *ngIf="isShowOptionsBody" class="body">
        <!-- <div class="text-secondary pl-2">Select {{label}}</div> -->
        <div *ngIf="_options.length > 7 || _add_new" class="search-box">
            <img *ngIf="!_add_new" src="/assets/icons/search-left.svg" alt="search" class="search-icon">
            <input type="text" class="form-control form-control-sm" [(ngModel)]="searchText"
                [placeholder]="_add_new ? 'Enter': 'Search'" (keyup)="onKeyUp($event)">
        </div>
        <div class="items-list pt-1">
            <!-- <div *ngIf="!searchText" class="item" (click)="onSelectOption('')">All</div> -->
            <ng-container *ngIf="!_optionValue">
                <div class="item text-truncate" [title]="option" [class.fs-085]="_isSmallInput"
                    (click)="onSelectOption(option)" *ngFor="let option of _options | filter : searchText">
                    {{option}}
                </div>
            </ng-container>
            <ng-container *ngIf="_optionValue && _optionText">
                <div class="d-flex item text-truncate" [class.fs-085]="_isSmallInput" [title]="option[_optionText]"
                    (click)="onSelectOption(option)"
                    *ngFor="let option of _options | filter : searchText : _optionText">
                    <div class="flex-grow-1">
                        {{option[_optionText]}}
                        <div *ngIf="_optionText1" class="text-light1">{{option[_optionText1]}}</div>
                    </div>
                    <div>
                        <img *ngIf="_show_edit" class="edit-icon hand float-right mr-2" alt="edit"
                            src="/assets/icons/ic_edit.svg" (click)="onEdit(option, $event)" title="Edit">
                    </div>
                </div>
            </ng-container>
            <div *ngIf="_options.length === 0" class="sub-title2 py-2 text-center">
                {{ _helpText }}
            </div>
            <div *ngIf="_add_new" class="text-center">
                <p *ngIf="filtered_items_count" class="text-light1 mb-1">No Items Found</p>
                <button *ngIf="_addNewLabel == 'Add new'" class="btn btn-outline-primary btn-sm mb-1 add-new-list"
                    (click)="onAddNew()">{{_addNewLabel}}</button>
                <button *ngIf="_addNewLabel != 'Add new'" class="btn btn-primary mb-1 btn-block"
                    (click)="onAddNew()">{{_addNewLabel}}</button>
            </div>
        </div>
    </div>
</div>