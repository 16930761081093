import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Business } from '../service/Business.service';
import { UrlService } from '../service/url.service';

@Component({
  selector: 'app-user-knowledge-series',
  templateUrl: './user-knowledge-series.component.html',
  styleUrls: ['./user-knowledge-series.component.scss']
})
export class UserKnowledgeSeriesComponent implements OnInit {
  @Input() user_module_link;

  knowledge_series = [];
  searchText$: Subject<string> = new Subject<string>();

  showFilters = false;
  filter_count: any;
  bol_filter = false;

  technologies = [];
  industries = [];
  products = [];
  filtered_product_list = [];
  filter_technologies = [];
  filter_industries = [];
  filter_products = [];
  filter_technologies_ids = '';
  filter_industries_ids = '';
  filter_products_ids = '';

  upcoming_session = null;

  constructor(
    private businessService: Business,
    private router: Router,
    private urlService: UrlService
  ) { }

  ngOnInit(): void {
    if (this.urlService.previous_url?.includes('knowledge-series-details')) {
      this.getAppliedFilters();
    } else {
      this.onClearFilters();
    }

    this.getAllKnowledgeSeries();
    this.getUpcomingSessionDetails();
    this.getTechnologies();
    this.getIndustries();
    this.getProducts();

    this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((searchText) => {
        return this.businessService.getAllKnowledgeSeries(searchText, this.filter_technologies_ids,
          this.filter_industries_ids, this.filter_products_ids);
      })
    ).subscribe((data: any) => {
      this.knowledge_series = data;
    });
  }

  onSearchKeyUp(event) {
    this.searchText$.next(event.target.value);
  }

  getAllKnowledgeSeries() {
    this.businessService.getAllKnowledgeSeries('', this.filter_technologies_ids,
      this.filter_industries_ids, this.filter_products_ids).subscribe((data) => {
        this.knowledge_series = data;
      });
  }

  getUpcomingSessionDetails() {
    this.businessService.getCurrentUpcomingSessionDetails().subscribe((data) => {
      this.upcoming_session = data;
      this.upcoming_session.time_texts = data.time_details.split('\n');
    })
  }

  getTechnologies() {
    this.businessService.getAllTechnologies().subscribe((data) => {
      this.technologies = data;
    });
  }

  getIndustries() {
    this.businessService.getAllIndustries().subscribe((data) => {
      this.industries = data;
    });
  }

  getProducts() {
    this.businessService.getAllPrimaryProducts1().subscribe((data) => {
      this.products = data;
      this.filtered_product_list = data;
    });
  }

  onIndustryChange() {
    this.filter_products = [];
    const industry_ids = this.filter_industries.map(x => x.id);
    this.filtered_product_list = this.products
      .filter(x => industry_ids.some(ind_id => ind_id == x.industry));
  }

  onClearFilters() {
    this.filter_technologies = [];
    this.filter_industries = [];
    this.filter_products = [];
    this.filter_technologies_ids = '';
    this.filter_industries_ids = '';
    this.filter_products_ids = '';

    this.getAllKnowledgeSeries();
    this.filter_count = 0;
    this.bol_filter = false;
    this.showFilters = false;
    this.saveAppliedFilters();
  }

  onApplyFilters() {
    this.filter_technologies_ids = this.filter_technologies.map(x => x.id).join();
    this.filter_industries_ids = this.filter_industries.map(x => x.id).join();
    this.filter_products_ids = this.filter_products.map(x => x.id).join();
    this.getAllKnowledgeSeries();
    this.setFilterCount();
    this.showFilters = false;
    this.saveAppliedFilters();
  }

  saveAppliedFilters() {
    const filters = JSON.stringify({
      filter_technologies: this.filter_technologies,
      filter_industries: this.filter_industries,
      filter_products: this.filter_products
    });
    sessionStorage.setItem('user_knowledge_filters', filters);
  }

  getAppliedFilters() {
    const sol_filters = sessionStorage.getItem('user_knowledge_filters');
    if (sol_filters) {
      const filters = JSON.parse(sol_filters);
      this.filter_technologies = filters.filter_technologies;
      this.filter_industries = filters.filter_industries;
      this.filter_products = filters.filter_products;
      if (this.filter_technologies.length > 0) {
        this.filter_technologies_ids = this.filter_technologies.map(x => x.id).join();
      }
      if (this.filter_industries.length > 0) {
        this.filter_industries_ids = this.filter_industries.map(x => x.id).join();
      }
      if (this.filter_products.length > 0) {
        this.filter_products_ids = this.filter_products.map(x => x.id).join();
      }
      this.setFilterCount();
    }
  }

  setFilterCount() {
    let nameList = [];
    if (this.filter_technologies.length != 0) {
      nameList.push(this.filter_technologies);
    }
    if (this.filter_industries.length != 0) {
      nameList.push(this.filter_industries);
    }
    if (this.filter_products.length != 0) {
      nameList.push(this.filter_products);
    }
    this.filter_count = nameList.length;

    if (nameList.length == 0) {
      this.bol_filter = false;
    } else {
      this.bol_filter = true;
      this.filter_count = nameList.length;
    }
  }

  onViewDetails(insight) {
    const nav_link = `/${this.user_module_link}/knowledge-series-details`;
    this.router.navigate([nav_link], { queryParams: { id: insight.id } });
  }

  onLinkClick(link) {
    if (this.user_module_link == 'individual') {
      link = '';
    }
    const nav_link = `/${this.user_module_link}/${link}`;
    this.router.navigate([nav_link]);
  }

  onSessionRegister() {
    window.open(this.upcoming_session.registration_link, '_blank');
  }
}
