import { environment } from 'src/environments/environment';
import { InjectionToken } from '@angular/core';

const EMAIL_REGEXP =
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const WEBSITE_URL_REGEXP = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
const PHONE_REGEXP = new RegExp('^[0-9]{6,14}$');
const NUMBER_OR_DECIMAL = /^\d+(\.\d+)?$/g

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export interface AppConfig {
    baseUrl: string;
    mobile_app_link_url: string;
    nodeJsSocketIOUrl: string
    title: string;
    emailPattern: RegExp;
    websiteUrlPattern: RegExp;
    phoneNumberPattern: RegExp;
    number_or_decimal: RegExp;

}
export const EW_DI_CONFIG: AppConfig = {
    baseUrl: environment.DEV_DOMAIN_URL,
    mobile_app_link_url: environment.MOBILE_APP_LINK_URL,
    nodeJsSocketIOUrl: environment.NODE_JS_SOCKET_IO_URL,
    title: 'My.Win',
    emailPattern: EMAIL_REGEXP,
    websiteUrlPattern: WEBSITE_URL_REGEXP,
    phoneNumberPattern: PHONE_REGEXP,
    number_or_decimal: NUMBER_OR_DECIMAL
};