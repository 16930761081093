import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';

import { AlertService } from '../service/alert.service';

import { ConfirmedValidator } from './confirmed.validator';

@Component({
  selector: 'app-resetpwd',
  templateUrl: './resetpwd.component.html',
  styleUrls: ['./resetpwd.component.scss']
})
export class ResetPwdComponent implements OnInit {
  resetPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  forgotSubmit = false;
  resetSubmit = false;
  showForgotPassword: boolean;
  showLoginForm: boolean;
  showResetPassword: boolean;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private alertService: AlertService) {

      }

  ngOnInit() {

      let key = '';
      this.route.queryParams.subscribe(params => {
            key = params['key'];
            // console.log(key);
        });

      this.resetPasswordForm = this.formBuilder.group({
        key: [key, [Validators.required]],
        password: ['', Validators.required],
        confirm_password: ['', Validators.required]
      }, {
          validator: ConfirmedValidator('password', 'confirm_password')
        })
  }


  // convenience getter for easy access to form fields
  get reset() { return this.resetPasswordForm.controls; }



    resetPasswordSubmit() : void {
        this.resetSubmit = true;

        // stop here if form is invalid
        if (this.resetPasswordForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.resetPassword(this.reset.key.value, this.reset.password.value, this.reset.confirm_password.value)
            .pipe(first())
            .subscribe(data => {
                    let message = "Password reset successfully."
                    this.alertService.success(message);
                    this.router.navigate(['/login']);
            },
            error => {
                let message = "Incorrect credential, please try again."
                this.alertService.error(message);
                this.loading = false;
            });
    }
}
