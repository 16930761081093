import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Business } from 'src/app/service/Business.service';

@Pipe({
  name: 'ewurl'
})
export class EWURL implements PipeTransform {

  constructor(
    private businessService: Business
  ) { }

  transform(value: string, ...args: unknown[]): Observable<string> {
    if (!value) return of(value);
    return this.businessService.getAzureBlobUrl(value).pipe(
      map(resp => {
        return resp?.blob_url;
      })
    );
  }

}
