<div *ngIf="knowledge_series" class="layout-padding form-group">
    <div class="mb-4">
        <span class="text-breadcrumb-link" (click)="onLinkClick('my-learning')">myLearning</span> /
        <span class="text-breadcrumb-link" (click)="onLinkClick('knowledge-series')">Knowledge Series</span>
    </div>
    <div>
        <div>
            <div class="page-title">
                {{knowledge_series.title}}
            </div>
            <div class="text-light0">
                <span *ngIf="knowledge_series.views > 0">
                    {{knowledge_series.views}} view<span *ngIf="knowledge_series.views > 1">s</span>
                    <span> &#183; </span>
                </span>
                {{knowledge_series.month_of_installation}}, {{knowledge_series.year_of_installation}}
            </div>
        </div>
        <div class="row" style="margin-bottom: 48px">
            <div class="col-md-8 col-12">
                <div *ngIf="knowledge_series.embed_url" class="vimeo-container">
                    <iframe [src]="knowledge_series.embed_url | safe" height=360 frameborder=0
                        allow="autoplay; fullscreen; picture-in-picture;"
                        style="position:absolute;top:0;left:0;width:100%;height:100%;"
                        [title]="knowledge_series.title"></iframe>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="h-100 border round-8 p-3">
                    <div class="sub-head-18 form-group">
                        Key Points of Discussion
                    </div>
                    <div class="pl-2 form-group">
                        <div *ngFor="let point of key_points">{{point}}</div>
                    </div>
                    <div *ngIf="knowledge_series?.notes_document_details.length > 0">
                        <button type="button" class="btn btn-sm btn-warning rounded-0 text-white"
                            (click)="onViewNotes(notes_documents)">View notes</button>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="form-group" *ngFor="let speaker of knowledge_series.speakers_set; let last = last;">
            <div class="py-1">
                <div class="d-flex align-items-center">
                    <div class="border mr-3" style="width: 48px; height: 48px; border-radius: 50%; overflow: hidden;">
                        <img *ngIf="speaker.profile_photo_blob" [src]="speaker.profile_photo_blob | ewurl | async"
                            class="no-blurry" style="width: 100%; height: auto;">
                        <span *ngIf="!knowledge_series.profile_photo_blob" class="profile-pic-letter">
                            {{speaker.name[0]}}
                        </span>
                    </div>
                    <div class="flex-grow-1">
                        <div class="sub-title1">{{speaker.name}}</div>
                        <div class="text-light1">{{speaker.designation}}</div>
                        <div class="text-light1">{{speaker.organization}}</div>
                        <div class="text-light0 mt-1">{{speaker.description}}</div>
                    </div>
                </div>
            </div>
            <hr *ngIf="!last">
        </div>
        <div class="form-group mt-5">
            <div class="tag mr-2 mb-2" *ngFor="let tag of tags">{{tag}}</div>
        </div>
    </div>

    <hr>
    <div class="my-2 p-2 comments-section">
        <ng-container *ngIf="!this.comment_form.id">
            <div class="d-flex mb-2">
                <div class="border mr-3" style="width: 48px; height: 48px; border-radius: 50%; overflow: hidden;">
                    <img *ngIf="current_user?.user_logo_blob"
                        [src]="(current_user?.user_logo_blob | ewurl | async) || (current_user.user_logo | safe)"
                        class="no-blurry" style="width: 100%; height: auto;">
                    <span *ngIf="!current_user?.user_logo_blob" class="profile-pic-letter">
                        {{current_user?.first_name[0]}}
                    </span>
                </div>
                <div class="flex-grow-1">
                    <textarea class="form-control font-size-14" [(ngModel)]="comment_form.content"
                        placeholder="Post your comments here..."></textarea>
                </div>
            </div>
            <div class="text-right form-group">
                <button type="button" class="btn btn-primary" style="width: 80px;" (click)="postComment()">Post</button>
            </div>
        </ng-container>
        <div class="sub-head-20 form-group">Comments</div>
        <div style="overflow: auto; max-height: 450px;">
            <div class="form-group d-flex p-2 rounded" style="background-color: #f3f3f3;"
                *ngFor="let comment of comments; let i = index;">
                <div class="border mr-3" style="width: 48px; height: 48px; border-radius: 50%; overflow: hidden;">
                    <img *ngIf="comment?.user_logo_blob"
                        [src]="(comment?.user_logo_blob | ewurl | async) || (comment.user_logo | safe)"
                        class="no-blurry" style="width: 100%; height: auto;">
                    <span *ngIf="!comment?.user_logo_blob" class="profile-pic-letter">
                        {{comment.created_by_name[0]}}
                    </span>
                </div>
                <div class="flex-grow-1">
                    <div>
                        <ng-container *ngIf="comment_form.id != comment.id">
                            <span class="text-bold">{{comment.created_by_name}}</span>
                            {{comment.content}}
                        </ng-container>
                        <ng-container *ngIf="comment_form.id == comment.id">
                            <textarea class="form-control font-size-14 mb-2" [(ngModel)]="comment_form.content"
                                autofocus></textarea>
                            <div class="text-right">
                                <button class="btn btn-sm btn-outline-primary mr-2"
                                    (click)="updateComment(comment)">Update</button>
                                <button class="btn btn-sm btn-outline-secondary"
                                    (click)="cancelEditComment()">Cancel</button>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="comment_form.id != comment.id">
                        <span class=" text-light1" [title]="comment.created_on | date">{{comment.created_on |
                            relativeDate}}</span>
                        <span *ngIf="comment.modified_by" class="text-light1 ">
                            , edited
                            <span class="" [title]="comment.modified_on | date">{{comment.modified_on |
                                relativeDate}}</span>
                        </span>
                        <ng-container *ngIf="comment?.can_edit_delete">
                            <span class="mx-2" style="cursor: pointer !important;"
                                (click)="onEditComment(comment)">Edit</span>
                            <span class="hand" style="cursor: pointer !important;"
                                (click)="deleteComment(comment)">Delete</span>
                        </ng-container>
                    </div>
                    <div *ngIf="comment.replies[0]" class="pl-4 mt-2">
                        <div class="font-size-12 text-secondary">
                            <strong>Replies</strong>
                        </div>
                        <span class="text-bold">Admin</span> {{comment.replies[0].content}}
                        <div>
                            <span class="text-light1 "
                                [title]="comment.replies[0].created_on | date">{{comment.replies[0].created_on
                                |
                                relativeDate}}</span>
                            <span *ngIf="comment.replies[0].modified_by" class="text-light1 ">
                                , edited
                                <span [title]="comment.replies[0].modified_on | date">{{comment.replies[0].modified_on
                                    |
                                    relativeDate}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="related_knowledge_series.length > 0">
        <hr>
        <div class="form-group">
            <div class="page-title my-2">
                Other related knowledge Series
            </div>

            <div>
                <div class="row">
                    <div class="col-md-4 mb-4  d-flex" *ngFor="let knowledge_series of related_knowledge_series"
                        (click)="onViewDetails(knowledge_series)">
                        <div class="knowledge_series-item flex-fill d-flex flex-column justify-content-between">
                            <div>
                                <div class="border form-group" style="height: 215px;">
                                    <img [src]="knowledge_series.thumbnail_image_blob | ewurl | async"
                                        class="no-blurry bg-white" style="width: 100%; height: 100%;">
                                </div>
                                <div appTruncateContent [maxLines]="2" class="sub-head-20 form-group"
                                    style="overflow: hidden;">
                                    {{knowledge_series.title}}
                                </div>
                                <div appTruncateContent class="text-light0 form-group"
                                    [innerHTML]="knowledge_series.content">
                                </div>
                                <div class="text-light1">
                                    {{knowledge_series.month_of_installation}},
                                    {{knowledge_series.year_of_installation}}
                                </div>
                            </div>
                            <div *ngIf="knowledge_series.speakers_set[0]">
                                <div class="border-top mb-2"></div>
                                <div class="d-flex align-items-center">
                                    <div class="border mr-3"
                                        style="width: 48px; height: 48px; border-radius: 50%; overflow: hidden;">
                                        <img *ngIf="knowledge_series.speakers_set[0].profile_photo_blob"
                                            [src]="knowledge_series.speakers_set[0].profile_photo_blob | ewurl | async"
                                            class="no-blurry" style="width: 100%; height: auto;">
                                        <span *ngIf="!knowledge_series.speakers_set[0].profile_photo_blob"
                                            class="profile-pic-letter">
                                            {{knowledge_series.speakers_set[0].name[0]}}
                                        </span>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="sub-head-16">{{knowledge_series.speakers_set[0].name}}</div>
                                        <div class="text-light1 font-size-14">{{knowledge_series.speakers_set[0].designation}}</div>
                                        <div class="text-light1 font-size-14">{{knowledge_series.speakers_set[0].organization}}</div>
                                    </div>
                                    <div *ngIf="knowledge_series.speakers_set.length > 1" class="align-self-start">
                                        <div class="border d-flex align-items-center justify-content-center"
                                            style="height: 32px;width: 32px;border-radius: 24px;font-size: 16px;font-weight: 700;">
                                            +{{knowledge_series.speakers_set.length - 1}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </ng-container>
</div>


<ng-template #notes_documents>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Notes</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="onCloseNotesDocuments()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <app-ew-file-upload2 [in_files]="knowledge_series?.notes_document_details" [read_only]="true">
            </app-ew-file-upload2>
        </div>
    </div>
</ng-template>