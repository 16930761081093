<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">

<div class="container-fluid">
    <div class="row">
        <div class="col-md-5 loginCss">


          <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPasswordSubmit()">
            <h2 class="text-center mb-50">Reset Password</h2>

            <div class="form-group" [hidden]="true">
            <div class="input-group">
              <input type="text" formControlName="key" class="form-control input100" [ngClass]="{ 'is-invalid': resetSubmit && reset.key.errors }" placeholder="Reset key" />

              <div *ngIf="resetSubmit && reset.key.errors" class="invalid-feedback">
                <div *ngIf="reset.key.errors.required">Reset key is required</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="input-group">
              <input type="password" formControlName="password" class="form-control input100" [ngClass]="{ 'is-invalid': resetSubmit && reset.password.errors }" placeholder="New Password" />

              <div *ngIf="resetSubmit && reset.password.errors" class="invalid-feedback">
                <div *ngIf="reset.password.errors.required">New password is required</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="input-group">
              <input type="password" formControlName="confirm_password" class="form-control input100" [ngClass]="{ 'is-invalid': resetSubmit && reset.confirm_password.errors }" placeholder="Confirm Password" />

              <div *ngIf="resetSubmit && reset.confirm_password.errors" class="invalid-feedback">
                <div *ngIf="reset.confirm_password.errors.required">Confirm password is required</div>
                <div *ngIf="reset.confirm_password.errors.confirmedValidator">Passwords did not match</div>
              </div>
            </div>
          </div>

          <button [disabled]="loading" class="btn btn-primary wd100">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Reset Password
          </button>
          </form>





          <div>
            <!-- <h6 class="footer"> 2020 ENABLING.WIN. All rights reserved</h6> -->
          </div>
        </div>
        <div class="col-md-6 text-center">
            <img src="/assets/img/loginImage.jpg" class="no-blurry mt-5" alt="login-image">
            </div>


        </div>
</div>
