<div class="row">
    <ng-container *ngIf="!_read_only">
        <div *ngIf="!_single_upload || (_single_upload && getNotDeletedFilesCount() === 0)" class="col-md-6"
            [class.col-md-12]="getNotDeletedFilesCount() === 0">
            <div *ngIf="_show_file_label && !_read_only" class="form-group mb-2">
                <label>File Label <span class="text-danger">*</span></label>
                <input type="text" class="form-control" [(ngModel)]="file_label" [class.input-error]="file_label_error">
            </div>
            <div class="mb-2" style="background-color: #F4F6F8;padding: 8px;border-radius: 8px;position: relative;">
                <div style="border: 2px dashed lightgray;max-height: 290px;overflow:auto"
                    class="round-8 d-flex flex-column justify-content-center text-secondary hand"
                    [style.min-height.px]="_min_height">
                    <input *ngIf="upload_mode" type="file" id="sol-doc-input" [multiple]="_is_multiple"
                        class="hidden-file" (change)="onDocumentChange($event.target.files)">
                    <div *ngFor="let file of uploaded_files" class="bg-white py-1 px-3 border-bottom">
                        <div class="d-flex">
                            <div class="align-self-center mr-2">
                                <!-- <span class="fa fa-file-pdf-o fa-lg text-danger"></span> -->
                                <span><img width="32" height="32" [src]="helper.getExtensionType(file.name)"
                                        class="hand svg-white"></span>
                            </div>
                            <div class="flex-grow-1 text-truncate">
                                <strong>{{file.name}}</strong>
                                <div class="text-secondary fs-085">{{getFileSize(file.size)}}</div>
                            </div>
                            <div>
                                <span *ngIf="!is_view" class="fa fa-times mr-1 hand text-secondary"
                                    (click)="onRemoveUploadedFile(file, $event)"></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="upload_mode" class="text-center text-light1">
                        <span class="fa fa-file mb-2"></span>
                        <div>Drag & Drop a file</div>
                        <div>or click to upload the document</div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button class="btn btn-outline-secondary" (click)="onDocumentAdd()"
                    [disabled]="uploaded_files.length === 0 || doc_uploading">
                    <span *ngIf="!doc_uploading">Add Document</span>
                    <span *ngIf="doc_uploading">
                        <span class="fa fa-circle-o-notch fa-spin fa-spin mr-1"></span> Uploading</span>
                </button>
            </div>
        </div>
    </ng-container>
    <div *ngIf="out_files.length > 0" class="col-md-6" [class.col-md-12]="_read_only">
        <div class="rounded border" style="border-bottom: none !important;">
            <div *ngFor="let doc of out_files">
                <div *ngIf="doc?.status != 'delete'" class="p-3 border-bottom">
                    <div class="d-flex">
                        <div class="align-self-center mr-2 hand" (click)="onDocumentClick(doc)">
                            <!-- <span class="fa fa-file-pdf-o fa-lg text-danger"></span> -->
                            <span><img width="32" height="32" [src]="helper.getExtensionType(doc.file_name)"
                                    class="hand svg-white"></span>
                        </div>
                        <div class="flex-grow-1 text-truncate hand" (click)="onDocumentClick(doc)">
                            <!-- <a class="sub-title2" *ngIf="doc.document_url" [href]="doc.document_url || doc.document"
                                target="_blank" (click)="logDownload(doc.id)">{{doc.file_name}}</a> -->
                            <a class="sub-title2 hand">
                                <span *ngIf="!_show_file_label">{{doc.file_name}}</span>
                                <span *ngIf="_show_file_label">{{getFileLabelExtension(doc)}}</span>
                            </a>
                            <!-- <span *ngIf="!doc.document_url && !doc.blob_url" type="button" class="hannd sub-title2"
                                (click)="downloadDocument(doc)">{{doc.file_name}}</span> -->
                            <div class="text-light1">
                                {{doc.size || doc.document_size}}kb
                            </div>
                        </div>
                        <div *ngIf="!_read_only" class="">
                            <span *ngIf="!is_view" class="fa fa-trash mr-1 hand" (click)="onRemoveItemDoc(doc)"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>