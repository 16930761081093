<ng-template #userProfile>
    <div>
        <div class="mb-2">
            <span class="fa fa-user-circle ml-5 mt-2 lg-dropdown"></span>
            <span class="ligt-link" (click)="onProfileLinkClick()">View Profile</span>
        </div>
        <div (click)="logout()">
            <span class="fa fa-sign-out ml-5 mt-2 lg-dropdown"></span>
            <span class="ligt-link">Logout</span>
        </div>
    </div>
</ng-template>


<div *ngIf="toggleSideMenu" class="admin-side-menu">
    <div class="logo-size">
        <!-- <div *ngIf="toggleSideMenu" class="d-inline-block hand text-black cross-side"
            (click)="toggleSideMenu = !toggleSideMenu">
            <span class="fa fa-times fa-3x"></span>
        </div> -->
        <img src="/assets/svg-icon/logo.svg" alt="My.Win logo" class="img-fluid no-blurry" routerLink="/individual">
    </div>
    <ng-container *ngFor="let link of links">
        <div *ngIf="link.f_icon" class="menu-item" (click)="onLinkClick(link)"
            [class.active-menu-item]="selectedLink && link.name == selectedLink.name">

            <div class="link-item-wrap" (click)="onLinkClick(link)"
                [class.active-link]="selectedLink && link.name == selectedLink.name">

                <img src='{{"/assets/svg-icon/"+link.f_icon+".svg"}}' class="img-fluid no-blurry icon-size">
                <span class="link-name">{{link.name}}</span>
            </div>
            <div class="child-links-wrap">
                <div class="py-3" style="text-transform: capitalize;" (click)="onChildLinkClick(child)"
                    *ngFor="let child of link.children">
                    {{child.name | titlecase}}
                </div>
            </div>
        </div>
    </ng-container>

    <div class="profile-bottom">
        <hr class="profile-bottom-hr">
        <div>
            <img *ngIf="currentUser?.user_logo" [src]="currentUser?.user_logo" alt="Avatar" class="user-avatar">
            <img *ngIf="!currentUser?.user_logo" src="/assets/img/sampleImage.png" alt="Avatar" class="user-avatar">
            <span [popover]="userProfile" placement="right" [outsideClick]="true">
                <span class="fa fa-angle-right d-arrow1"></span>
            </span>
        </div>
        <span class="px-2 user-txt">{{fullname}}</span>

        <img [style.opacity]="currentUser?.company_logo ? 1 : 0" [src]="currentUser?.company_logo" alt="Avatar"
            class="company-logo no-blurry">
    </div>
</div>
<div class="admin-body" [class.full-body]="!toggleSideMenu">

    <div *ngIf="!toggleSideMenu" class="bottom-nav-menu">
        <ng-container *ngFor="let link of mob_links">
            <div *ngIf="link.f_icon" class="menu-item" (click)="onLinkClick(link);onMobLinkClick(link)"
                [class.active-menu-item]="selectedLink && link.name == selectedLink.name">
                <div class="link-item-wrap">
                    <img src='{{"/assets/svg-icon/"+link.f_icon+".svg"}}' class="img-fluid no-blurry icon-size">
                    <span class="link-name">{{link.name}}</span>
                </div>
            </div>
        </ng-container>

    </div>
    <div class="container-fluid p-0 mobile_bottom">
        <router-outlet></router-outlet>
    </div>
</div>