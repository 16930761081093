<div *ngIf="_isDocFile" class="files-list">
  <button *ngIf="fileUpload" class="delete-file" (click)="deleteFile()">
    <img src="./assets/img/Recycle_Bin_Full.png" style="width: 20px;height: 20px; margin-top: -10px;">
  </button>
  <p *ngIf="fileUpload || fileUrl" style="margin-top: -3px;"><b>{{label}}</b></p>
  <p *ngIf="!(fileUpload || fileUrl)" style="color: gray; margin-top: -3px;">{{label}}</p>
  <div class="uploadfilecontainer2" (click)="fileInput.click()" appDragDrop
    (onFileDropped)="uploadFile($event.target.files)">
    <p style="margin: 10px 0px;">upload</p>
    <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
  </div>
</div>

<ng-container *ngIf="!_isDocFile">
  <label *ngIf="label">{{label}}</label>
  <div class="upload-btn-wrapper">
    <input type="text" class="form-control" [class.form-control-sm]="_isSmallInput" [value]='fileName'>
    <input type="file" class="hidden-file" (change)="uploadFile($event.target.files)">
    <span [ngClass]="_isSmallInput ? 'upload-btn-sm' : 'upload-btn'">
      Upload
      <span class="fa fa-plus-circle fa-lg py-2"></span>
      <!-- <img src="/assets/img/upload_doc.png" alt="close"
        [ngClass]="_isSmallInput ? 'p-1 upload-btn-icon-sm' : 'p-1 upload-btn-icon'"> -->
    </span>
  </div>
</ng-container>