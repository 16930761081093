import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { differenceInMinutes } from 'date-fns';
import { first } from 'rxjs/operators';
import { Business } from 'src/app/service/Business.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-user-insight-details',
  templateUrl: './user-insight-details.component.html',
  styleUrls: ['./user-insight-details.component.scss']
})
export class UserInsightDetailsComponent implements OnInit {
  @Input() user_module_link;

  insight_id = null;
  insight = null;
  tags = [];
  related_insights = [];
  comment_form = { id: null, insight: null, content: '' };
  comments = [];
  current_user = null;

  constructor(
    private businessService: Business,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialog: ConfirmDialogService
  ) { }

  ngOnInit(): void {
    this.current_user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.route.queryParams.subscribe((params) => {
      this.insight_id = params['id'];
      if (this.insight_id) {
        this.getInsightDetails();
      }
    });
  }

  getInsightDetails() {
    this.businessService.getInsightDetails(this.insight_id).subscribe((resp) => {
      this.insight = resp;
      this.tags = [...resp.technologies_set.map(x => x.name), ...resp.industries_set.map(x => x.name), ...resp.products_set.map(x => x.name)];
      this.comments = this.insight.comments;
      this.setCommentEditDeletePermission()
      this.getRelatedInsights();
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
      this.updateView();
    });
  }

  getRelatedInsights() {
    this.businessService.getRelatedInsights(this.insight_id).subscribe((data) => {
      this.related_insights = data.slice(0, 3);
    });
  }

  updateView() {
    this.businessService.updateInsightView({ insight: this.insight_id }).subscribe();
  }

  postComment() {
    if (!this.comment_form.content) return;
    this.comment_form.insight = this.insight_id;
    this.businessService.addInsightComment(this.comment_form).subscribe((resp) => {
      this.comments.unshift(resp);
      this.setCommentEditDeletePermission();
      this.comment_form = { id: null, insight: null, content: '' };
    });
  }

  updateComment(comment) {
    if (!this.comment_form.content) return;
    this.comment_form.insight = this.insight_id;
    this.businessService.updateInsightComment(this.comment_form).subscribe((resp) => {
      this.setCommentEditDeletePermission();
      comment.content = resp.content;
      comment.modified_by = resp.modified_by;
      comment.modified_on = resp.modified_on;
      this.comment_form = { id: null, insight: null, content: '' };
    });
  }

  onEditComment(comment) {
    this.comment_form = { ...comment };
  }

  cancelEditComment() {
    this.comment_form = { id: null, insight: null, content: '' };
  }

  deleteComment(comment) {
    this.confirmDialog.showConfirm(`Are you sure you want to delete this comment "${comment.content}"`).pipe(first()).subscribe((status) => {
      if (status) {
        this.businessService.deleteInsightComment(comment.id).subscribe((resp) => {
          const delete_index = this.comments.findIndex(c => c.id == comment.id);
          this.comments.splice(delete_index, 1);
        });
      }
    });
  }

  setCommentEditDeletePermission() {
    this.comments.forEach(c => c.can_edit_delete = false);
    const user_latest_post = this.comments.find(c => c?.created_by_user_name == this.current_user.username);
    if (user_latest_post) {
      const posted_at = new Date(user_latest_post.created_on)
      const current_time = new Date();
      const minutes_difference = differenceInMinutes(current_time, posted_at);
      if (minutes_difference < 5) {
        user_latest_post.can_edit_delete = true;
      }
    }
  }

  onViewDetails(insight) {
    const nav_link = `/${this.user_module_link}/insight-details`;
    this.router.navigate([nav_link], { queryParams: { id: insight.id } });
  }

  onLinkClick(link) {
    if (this.user_module_link == 'individual') {
      link = '';
    }
    const nav_link = `/${this.user_module_link}/${link}`;
    this.router.navigate([nav_link]);
  }

}
