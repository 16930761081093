<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-3 col-md-6 col-lg-5 loginCss">


          <form [formGroup]="setPasswordForm" (ngSubmit)="setPasswordSubmit()">
            <h2 class="text-center mb-50">Set Password</h2>

            <div class="form-group" [hidden]="true">
            <div class="input-group">
              <input type="text" formControlName="key" class="form-control input100" [ngClass]="{ 'is-invalid': setSubmit && set.key.errors }" placeholder="Set Password key" />

              <div *ngIf="setSubmit && set.key.errors" class="invalid-feedback">
                <div *ngIf="set.key.errors.required">Set password key is required</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="input-group">
              <input type="password" formControlName="password" class="form-control input100" [ngClass]="{ 'is-invalid': setSubmit && set.password.errors }" placeholder="New Password" />

              <div *ngIf="setSubmit && set.password.errors" class="invalid-feedback">
                <div *ngIf="set.password.errors.required">New password is required</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="input-group">
              <input type="password" formControlName="confirm_password" class="form-control input100" [ngClass]="{ 'is-invalid': setSubmit && set.confirm_password.errors }" placeholder="Confirm Password" />

              <div *ngIf="setSubmit && set.confirm_password.errors" class="invalid-feedback">
                <div *ngIf="set.confirm_password.errors.required">Confirm password is required</div>
                <div *ngIf="set.confirm_password.errors.confirmedValidator">Passwords did not match</div>
              </div>
            </div>
          </div>
          <button [disabled]="loading" class="btn btn-primary wd100">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Set Password
          </button>
          </form>
          <div>
            <!-- <h6 class="footer"> 2020 ENABLING.WIN. All rights reserved</h6> -->
          </div>
        </div>
        <div class="col-sm-9 col-md-6 col-lg-7">
            <img src="/assets/img/loginImage.jpg" class="img-fluid" alt="login-image" style="height: 100% !important">
            </div>


        </div>
</div>
