import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mywinuser-profile',
  templateUrl: './mywinuser-profile.component.html',
  styleUrls: ['./mywinuser-profile.component.scss']
})
export class MywinuserProfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
