import { Component, OnInit, Input, forwardRef, Output, EventEmitter, HostListener, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const EW_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EwMultiSelect2Component),
  multi: true
};

const noop = () => {
};

@Component({
  selector: 'app-ew-multi-select2',
  templateUrl: './ew-multi-select2.component.html',
  styleUrls: ['./ew-multi-select2.component.scss'],
  providers: [EW_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class EwMultiSelect2Component implements OnInit, ControlValueAccessor, AfterViewInit {
  @ViewChild('searchIn') searchIn: ElementRef;
  @HostListener('document:click', ['$event'])


  clickOut(event) {
    if (this.eRef.nativeElement.contains(event.target)) {

    } else {
      this.closeOptionsBody();
    }
  }

  @Input() set options(value: any[]) {
    this._options = value;
    this.getOptionFromModel(this.selectedOption);
  }

  @Input() set optionValue(value: any) {
    this._optionValue = value;
  }

  @Input() set optionText(value: any) {
    this._optionText = value;
  }

  @Input() set optionText1(value: any) {
    this._optionText1 = value;
  }

  @Input() set read_only(value: any) {
    this._read_only = value;
  }

  @Input() set helpText(value: any) {
    this._helpText = value;
  }

  @Output() change = new EventEmitter<any>();

  _options = [];
  _optionValue = null;
  _optionText = null;
  _optionText1 = null;
  selectedOption = '';
  searchText = '';
  selectedOptionText = '';
  _isSmallInput = false;
  isShowOptionsBody = false;
  selectedItems = [];
  selectedItemLabels = [];
  _joinSymbol = ','
  showLabelText = true;
  _read_only = false;
  _helpText = 'No Result Found';
  select_all = false;

  private onModelTouched: () => void = noop;
  private onModelChange: (_: any) => void = noop;

  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // this.searchIn.nativeElement.focus();
  }

  writeValue(value: any): void {
    // this.selectedOption = value;
    this.selectedItems = value;
    this.select_all = (this.selectedItems?.length === this._options?.length) ? true : false;
    if (!value) {
      this.selectedItemLabels = [];
      this.selectedItems = [];
    }
    //this.getOptionFromModel(value);
  }

  getOptionFromModel(value) {
    if (this._optionValue && this._options) {
      const myOption = this._options.find((item) => item[this._optionValue] == this.selectedOption);
      if (myOption) {
        this.selectedOptionText = myOption[this._optionText];
      }
    } else {
      this.selectedOptionText = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  onOptionChange() {
    this.onModelChange(this.selectedOption);
    this.change.emit(this.selectedOption);

    this.select_all = (this.selectedItems?.length === this._options?.length) ? true : false;
  }

  onItemSelectToggle(event, item) {

    if (this.isItemSelected(item[this._optionValue])) {
      this.removeSelectedItem(item)
      event.stopPropagation(); //Nikhil Changes

    } else {
      this.selectedItems.push(item);
      this.change.emit(this.selectedOption);
      event.stopPropagation();//Nikhil Changes

    }
    this.onModelChange(this.selectedItems);

    this.select_all = (this.selectedItems?.length === this._options?.length) ? true : false;
  }

  onSelectAllToggle(event) {
    this.select_all = !this.select_all;
    if (this.select_all) {
      this.selectedItems = [...this._options];
    } else {
      this.selectedItems = [];
    }
    this.onModelChange(this.selectedItems);
    this.change.emit(this.selectedItems);
    event.stopPropagation();
  }

  isItemSelected(val): boolean {
    return this.selectedItems.some(x => x[this._optionValue] == val);
  }

  removeSelectedItem(item) {
    const filterItems = this.selectedItems.filter(x => x[this._optionValue] != item[this._optionValue]);
    this.selectedItems = [...filterItems];
    this.onModelChange(this.selectedItems);
    this.change.emit(this.selectedOption);
    
    this.select_all = (this.selectedItems?.length === this._options?.length) ? true : false;
  }

  closeOptionsBody() {
    this.isShowOptionsBody = false;
    this.searchText = '';
  }

}
