import { Component, OnInit, Input, forwardRef, Output, EventEmitter, HostListener, ElementRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const EW_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EwSelectComponent),
  multi: true
};

const noop = () => {
};

@Component({
  selector: 'app-ew-select',
  templateUrl: './ew-select.component.html',
  styleUrls: ['./ew-select.component.scss'],
  providers: [EW_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class EwSelectComponent implements OnInit, ControlValueAccessor {
  @ViewChild('searchIn') searchIn: ElementRef;
  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (this.eRef.nativeElement.contains(event.target)) {

    } else {
      this.closeOptionsBody();
    }
  }
  @Input() label = 'Select';
  @Input() set options(value: any[]) {
    this._options = value;
    this.getOptionFromModel(this.selectedOption);
  }

  @Input() set optionValue(value: any) {
    this._optionValue = value;
  }

  @Input() set optionText(value: any) {
    this._optionText = value;
  }

  @Input() set optionText1(value: any) {
    this._optionText1 = value;
  }

  @Input() set IsSmallInput(value: boolean) {
    this._isSmallInput = value;
  }

  @Input() set read_only(value: any) {
    this._read_only = value;
  }

  @Input() set input_error(value: any) {
    this._input_error = value;
  }

  @Input() set helpText(value: any) {
    this._helpText = value;
  }

  @Input() no_head_border = false;

  @Input() set addNew(value: any) {
    this._add_new = value;
  }

  @Input() set hover_arrow(value: any) {
    this._hover_arrow = value;
  }

  @Input() set addNewLabel(value: any) {
    this._addNewLabel = value;
  }
  
  @Input() set show_edit(value: any) {
    this._show_edit = value;
  }

  @Output() change = new EventEmitter<any>();
  @Output() showToggle = new EventEmitter<boolean>();
  @Output() addItem = new EventEmitter<string>();
  @Output() editItem = new EventEmitter<string>();

  _options = [];
  _optionValue = null;
  _optionText = null;
  _optionText1 = null;
  selectedOption = '';
  searchText = '';
  selectedOptionText = '';
  selectedOptionText1 = '';
  _isSmallInput = false;
  isShowOptionsBody = false;
  _read_only = false;
  _input_error = false;
  _helpText = 'No Result Found';
  _add_new = false;
  _show_edit = false;
  filtered_items_count = 0;
  _hover_arrow = false;
  _addNewLabel = 'Add new';

  private onModelTouched: () => void = noop;
  private onModelChange: (_: any) => void = noop;

  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
  }

  writeValue(value: any): void {
    this.selectedOption = value;
    this.getOptionFromModel(value);
  }

  getOptionFromModel(value) {
    if (this._optionValue && this._options) {
      const myOption = this._options.find((item) => item[this._optionValue] == this.selectedOption);
      if (myOption) {
        this.selectedOptionText = myOption[this._optionText];
        this.selectedOptionText1 = myOption[this._optionText1];
      }
    } else {
      this.selectedOptionText = value;
      this.selectedOptionText1 = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  onOptionChange() {
    this.onModelChange(this.selectedOption);
    this.change.emit(this.selectedOption);
    this.showToggle.emit(this.isShowOptionsBody);
  }

  onSelectOption(option) {
    this.selectedOption = this._optionValue && option ? option[this._optionValue] : option;
    this.selectedOptionText = this._optionValue && option ? option[this._optionText] : option;
    this.selectedOptionText1 = this._optionValue && option ? option[this._optionText1] : '';
    this.onModelChange(this.selectedOption);
    this.change.emit(this.selectedOption);
    this.closeOptionsBody();
  }

  onRemoveSelection() {
    this.selectedOption = '';
    this.selectedOptionText = '';
    this.selectedOptionText1 = '';
    this.onModelChange(this.selectedOption);
    this.change.emit(this.selectedOption);
    this.showToggle.emit(this.isShowOptionsBody);
  }

  onKeyUp(event) {
    if (this._optionValue && this._optionText) {
      this.filtered_items_count = this._options.filter(x => x[this._optionText] == this.searchText).length;
    }
    this.filtered_items_count = this._options.filter(x => x == this.searchText).length;
  }

  closeOptionsBody() {
    this.isShowOptionsBody = false;
    this.searchText = '';
    this.showToggle.emit(this.isShowOptionsBody);
  }

  onHeadClick() {
    this.isShowOptionsBody = !this.isShowOptionsBody;
    this.showToggle.emit(this.isShowOptionsBody);
  }

  onAddNew() {
    if (!this.searchText && this._addNewLabel=='Add new') return;
    this.addItem.emit(this.searchText);
    this.closeOptionsBody();
  }

  onEdit(option, event) {
    event.stopPropagation();
    this.editItem.emit(option);
    this.closeOptionsBody();
  }
}
