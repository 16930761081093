import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  title = ''
  data = '';
  alt_label_text = '';

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
