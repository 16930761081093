import { Injectable } from "@angular/core";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfirmDialogService {
    modalRef: BsModalRef;
    onClose: Subject<boolean> = new Subject<boolean>();
    message = 'Please confirm ?'
    show_proceed = false;
    yes_text;
    no_text;

    constructor(private modalService: BsModalService) {
        // this.modalService.onHide().sub
    }

    showConfirm(msg, proceed = false, yes_text = 'Ok', no_text='Cancel'): Observable<boolean> {
        if (msg) {
            this.message = msg;
        }
        this.show_proceed = proceed;
        this.yes_text = yes_text;
        this.no_text = no_text;
        this.modalRef = this.modalService.show(ConfirmDialogComponent, { class: 'modal-dialog-centered' });
        return this.onClose.asObservable();
    }

    confirm() {
        this.modalRef.hide();
        this.onClose.next(true);
        // return this.onClose.asObservable();
    }

    cancel() {
        this.modalRef.hide();
        this.onClose.next(false);
        // return this.onClose.asObservable();
    }
}