import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AppConfig, APP_CONFIG } from 'src/app/app.config';
import { AlertService } from 'src/app/service/alert.service';
import { Business } from 'src/app/service/Business.service';
import { IndividualService } from '../individual.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-individual-home',
  templateUrl: './individual-home.component.html',
  styleUrls: ['./individual-home.component.scss']
})
export class IndividualHomeComponent implements OnInit {
  requesting = false;
  current_user: any;
  user_type = true;
  existing_domain: boolean;
  request_sent = false;
  modalRef?: BsModalRef;
  countries = [];
  request_demo_form = { company_name: '', company_contact: '', company_email: '', country_code: '' };
  request_demo_form_error = {
    company_name: false, company_contact: false, company_email: false,
    invalid_contact: false, invalid_email: false, country_code: false
  };

  constructor(
    private individualService: IndividualService,
    private modalService: BsModalService,
    private alertService: AlertService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private businessService: Business,
    private router: Router
  ) {
    this.current_user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.existing_domain = this.current_user?.existing_domain;
  }

  ngOnInit(): void {
    this.init();
    this.getCountries();
  }

  async init() {
    this.individualService.getRequestStatus().subscribe((resp) => {
      this.current_user = JSON.parse(sessionStorage.getItem('currentUser'));
      this.existing_domain = this.current_user?.existing_domain;
      this.request_sent = this.existing_domain ? resp.send_request_status : resp.demo_request_status;
    });
  }

  getCountries() {
    this.businessService.getAllCountries().subscribe(data => {
      this.countries = data.sort((a, b) => a.phone_code - b.phone_code);
    });
  }

  onMyLearningClick(endpoint) {
    if (endpoint == 'fut-ure') {
      const url = 'https://enabling.win/my-win-dashboard/' + endpoint + '/';
      window.open(url, '_blank');
    } else {
      this.router.navigate([endpoint]);
    }
  }

  onRequestDemo(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { keyboard: false, ignoreBackdropClick: true });
  }

  closeRequestDemoForm() {
    this.modalRef.hide();
    this.request_demo_form = { company_name: '', company_contact: '', company_email: '', country_code: '' };
  }

  onSendRequest() {
    let sendRequest$ = null
    if (!this.existing_domain) {
      this.request_demo_form_error = {
        company_name: false, company_contact: false, company_email: false,
        invalid_contact: false, invalid_email: false, country_code: false
      };
      if (!this.request_demo_form.company_name) {
        this.request_demo_form_error.company_name = true;
      }
      if (!this.request_demo_form.country_code) {
        this.request_demo_form_error.country_code = true;
      }
      if (!this.request_demo_form.company_contact) {
        this.request_demo_form_error.company_contact = true;
      } else if (!this.config.phoneNumberPattern.test(this.request_demo_form.company_contact)) {
        this.request_demo_form_error.invalid_contact = true;
      }

      if (!this.request_demo_form.company_email) {
        this.request_demo_form_error.company_email = true;
      } else if (!this.config.emailPattern.test(this.request_demo_form.company_email)) {
        this.request_demo_form_error.invalid_email = true;
      }

      if (JSON.stringify(this.request_demo_form_error).includes('true')) {
        return;
      }
      sendRequest$ = this.individualService.sendDemoRequest(this.request_demo_form);
    } else {
      sendRequest$ = this.individualService.sendOrgRequest();
    }

    if (sendRequest$) {
      this.requesting = true;
      sendRequest$.subscribe((resp) => {
        this.requesting = false;
        this.alertService.success('Thank you for reaching out. We’ll get  in touch with you as soon as possible.', true);
        window.location.reload();
        this.closeRequestDemoForm();
        this.request_sent = true;
      }, () => {
        this.requesting = false;
        this.alertService.error('failed to send request');
      });
    }
  }
}
