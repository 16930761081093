import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { first } from 'rxjs/operators';
import { AlertService } from '../service/alert.service';
import { Business } from '../service/Business.service';
import { APP_CONFIG, AppConfig } from '../app.config';

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate {

    loading = false;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private business: Business,
        @Inject(APP_CONFIG) private config: AppConfig,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const m_token = route.queryParams['refresh'];
        const username = route.queryParams['username'];
        const mobile_redirect_spr = route.queryParams['mobile_redirect_spr'];
        const mobile_redirect_sprs_oem = route.queryParams['mobile_redirect_sprs_oem'];
        const installed_equipment_id = route.queryParams['installed_equipment_id'];
        const mobile_redirect_sprs_fm = route.queryParams['mobile_redirect_sprs_fm'];
        const support_request_id = route.queryParams['support_request_id'];
        const request_type = route.queryParams['request_type'];
        if (m_token && username) {
            this.authenticationService.login_refresh(username, m_token).pipe(first()).subscribe(() => {
                if (state.url.includes('/oem/oem-support-requests') && mobile_redirect_sprs_oem) {
                    this.authenticationService.setIsMobileWebView(true);
                    this.router.navigate(['/oem/oem-support-requests'],
                        {
                            queryParams: {
                                support_request_mobile_redirect: mobile_redirect_sprs_oem,
                                support_request_id: support_request_id,
                                request_type: request_type
                            }
                        }
                    );
                } else if (state.url.includes('/customer/fm-support-requests') && mobile_redirect_sprs_fm) {
                    this.authenticationService.setIsMobileWebView(true);
                    this.router.navigate(['/customer/fm-support-requests'],
                        {
                            queryParams: {
                                support_request_mobile_redirect: mobile_redirect_sprs_fm,
                                support_request_id: support_request_id,
                                request_type: request_type
                            }
                        }
                    );
                } else if (state.url.includes('customer/customer-equipment-details') && mobile_redirect_spr && installed_equipment_id) {
                    this.authenticationService.setIsMobileWebView(true);
                    this.router.navigate(['/customer/customer-equipment-details'],
                        {
                            queryParams: {
                                support_request_mobile_redirect: mobile_redirect_spr,
                                installed_equipment_id: installed_equipment_id

                            }
                        });
                } else {
                    this.router.navigate(['/']);
                }
            }, error => {
                this.alertService.error(error);
                this.loading = false;
            });
        }

        const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
        const sp_regex_pattern = /\/(customer|oem)\/(fm-support-requests|oem-support-requests)\?token=([\w-]+)/;
        const matches = state.url.match(sp_regex_pattern);

        if (matches) {
            const link_token = matches[3];
            this.business.getLinkTokenData(link_token).subscribe((resp) => {
                if (isMobile) {
                    const appLink = `${this.config.mobile_app_link_url}${resp?.user_type}/${resp?.username}/supportRequestType/${resp?.support_request_id}/`;
                    window.location.href = appLink;
                    return true;
                } else {
                    this.router.navigate([state.url.split('?')[0]], {
                        queryParams: {
                            support_request_id: resp?.support_request_id,
                            request_type: resp?.request_type
                        }
                    });
                }
            });
        }

        const currentUser = sessionStorage.getItem('loggedInUserInfo');
        if (currentUser) {
            return true;
            // const user = JSON.parse(currentUser);
            // let dochub_permissions = [];
            // let dochub_features = [];
            // if (user.role_permissions) {
            //     let _permissions = user.role_permissions.find(x => x.name.toLowerCase() == 'dochub');
            //     if (_permissions) {
            //         dochub_permissions = _permissions.features;
            //     }
            // }
            // if (user.subscription && user.subscription.modules) {
            //     let modules = JSON.parse(user.subscription.modules);
            //     let _dochub_features = modules.find(x => x.name.toLowerCase() == 'dochub');
            //     if (_dochub_features) {
            //         dochub_features = _dochub_features.features;
            //     }
            // }
            // let sol_permissions = dochub_permissions.find(x => x.name.toLowerCase() == 'solutions');
            // let sol_feature = dochub_features.find(x => x.name.toLowerCase() == 'solutions');
            // let install_permissions_fm = dochub_permissions.find(x => x.name.toLowerCase() == 'installations fm');
            // let install_feature_fm = dochub_features.find(x => x.name.toLowerCase() == 'installations fm');
            // let install_permissions_oem = dochub_permissions.find(x => x.name.toLowerCase() == 'installations oem');
            // let install_feature_oem = dochub_features.find(x => x.name.toLowerCase() == 'installations oem');

            // if ((state.url.includes('admin') && user.user_type == 'Admin') || user.user_type == 'Individual' ||
            //     (state.url.includes('mywin-user') && user.user_type == 'My Win User')) {
            //     return true;
            // } else if ((state.url.includes('oem') && (user.user_type == 'OEM' || user.user_type == 'My Win User'))) {
            //     if (state.url.includes('equipment-list')) {
            //         if (install_feature_oem?.mapped && install_permissions_oem?.view) {
            //             return true;
            //         } else if (sol_feature?.mapped && sol_permissions?.view) {
            //             this.router.navigate(['/oem/solutions']);
            //             return true;
            //         } else {
            //             this.alertService.error('You are not authorized to view any pages, please contact admin to get required azuthorizations', true);
            //             this.router.navigate(['/login']);
            //             return false;
            //         }
            //     }
            //     return true;
            // } else if ((state.url.includes('customer') && (user.user_type == 'Food Manufacturer' || user.user_type == 'My Win User'))) {
            //     if (state.url.includes('cust-processline-list')) {
            //         if (install_feature_fm?.mapped && install_permissions_fm?.view) {
            //             return true;
            //         } else {
            //             this.alertService.error('You are not authorized to view any pages, please contact admin to get required azuthorizations', true);
            //             this.router.navigate(['/login']);
            //             return false;
            //         }
            //     }
            //     if (state.url.includes('processline-templates')) {
            //         if (install_feature_fm?.mapped && install_permissions_fm?.create) {
            //             return true;
            //         } else {
            //             this.alertService.error('Sorry? you are not authorized to view certain pages', true);
            //             this.router.navigate(['/login']);
            //             return false;
            //         }
            //     }
            //     return true;
            // } else if (user.user_type == 'My Win User') {
            //     return true;
            // } else if ((state.url.includes('welcome') && user.user_type == 'Individual')) {
            //     return true;
            // }
            // // else if ((state.url.includes('welcome') && user.user_type == 'My Win User')) {
            // //     return true;
            // // }
            // else {
            //     this.router.navigate(['/login']);
            //     return false;
            // }
        }

        // Store the attempted URL for redirection after login
        this.authenticationService.redirectUrl = state.url;

        this.router.navigate(['/login']);
        return false;
    }

}
