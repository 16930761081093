<div class="row no-gutters page-head sticky-top bg-white">
    <div class="col col-md-6">
        <h4 class="head-title">My Profile</h4>
    </div>
    <div class="col col-md-6" style="text-align: end;">

        <!-- <span class="fa fa-sign-out ml-5 mt-2 lg-dropdown"></span>
        <span class="ligt-link">Logout</span> -->
        <button class="btn btn-outline-primary mr-2" (click)="logout()" style="padding-top: 0px; vertical-align: baseline;
    height: 32px !important;">
            <span class="fa fa-sign-out lg-dropdown"></span> Logout
        </button>
    </div>
</div>
<div class="container-fluid p-0">
    <div class="layout-padding form-group clearfix">
        <app-user-profile></app-user-profile>
    </div>
</div>