<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">

<div *ngIf="!mobile_view" class="container-fluid p-0" style="overflow-x: hidden;scroll-behavior: smooth;">


  <div id="navbar" routerLink="/" class="logo-header border-bottom">
    <img src="/assets/logos/colour_ogo/Colour Logo (transparent bg) -02.png" alt="logo" style="width: 190px !important;"
      class="img-fluid no-blurry">
  </div>
  <div class="row align-items-center" style="margin-top: 80px;">
    <div class="col-5" style="padding-left: 10%;">
      <h1 class="home-title">Enhance your equipment performance by collaborating with your OEMs.</h1>
      <p class="mt-2" style="font-size: 20px;font-weight: 400;color: #4E4E50;width: 130%">
        Tailored for the food industry, My.Win provides a single platform for you and your OEMs to work seamlessly and
        ensure optimal efficiency, reliability and quality of your equipment.
      </p>
    </div>
    <div class="col-7 position-relative" style="background: url('/assets/logos/yellow_triangle.png');height: 520px;">
      <div class="hex-img11" style="position: absolute; left: 18%;">
        <div class="position-relative">
          <img src="/assets/logos/Polygon 1.svg" alt="logo" class="img-fluid no-blurry">
          <div style="position: absolute;top: 10px;left: 180px;right: 180px;">
            <div style="margin-top: 85px;" class="form-group text-center">
              <div class="d-flex text-center ew-tabs-wrapper">
                <div class="bg-light flex-fill py-2 hand" [class.active-tablogin]="showLoginForm"
                  (click)="onTabsToggle(true);">
                  Sign in
                </div>
                <div class="bg-light flex-fill py-2 hand" [class.active-tablogin]="!showLoginForm"
                  (click)="onTabsToggle(false)">
                  Register
                </div>
              </div>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="showLoginForm">
              <div class="form-group">
                <label for="username">Email</label>
                <input type="text" formControlName="username" placeholder="Email" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">Email is required</div>
                </div>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" formControlName="password" placeholder="Password" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6 pr-1">
                    <div class="custom-control">
                      <input type="checkbox" class="form-check-input">
                      <label class="">Keep me signed in</label>
                    </div>
                  </div>
                  <div class="col-md-6 text-center text-md-right pl-1 font-size-12">
                    <span type="button" class="text-primary" href="javascript:void(0)"
                      style="color: #328BCB !important;" (click)="showForm('Forgot')">Forgot
                      Password? </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button [disabled]="loading" class="btn btn-success wd100">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Sign in
                </button>
              </div>
            </form>

            <div *ngIf="!showLoginForm">

              <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" [(ngModel)]="newUser.name" [class.input-error]="ferror.name">
                <div *ngIf="ferror.name" class="text-danger fs-085">
                  Name is required
                </div>
              </div>
              <div class="form-group">
                <label>Email Address</label>
                <input type="text" class="form-control" autocomplete="new-password" [(ngModel)]="newUser.email_address"
                  [class.input-error]="ferror.email || ferror.inValidEmail">
                <div *ngIf="ferror.name" class="text-danger fs-085">
                  Email is required
                </div>
                <div *ngIf="ferror.inValidEmail" class="text-danger fs-085">
                  Enter a valid email address
                </div>
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-primary btn-block" (click)="onRegister()">Register</button>
              </div>
            </div>



          </div>
        </div>
      </div>

    </div>
  </div>

  <div style="background-color: #eaf2fd;padding: 38px 70px;">
    <div class="text-bold ml-3 form-group" style="font-size: 32px;">
      Modules
    </div>
    <div class="d-flex form-group" (mouseleave)="slide_module_init()">
      <div class="" style="width: 40%;">
        <div (mouseover)="onViewModule(1)" class="p-5 bg-white ml-3 border-bottom" style="border-left: 9px solid white"
          [style.borderColor]="slideShowModuleIndex == 1 ? '#D94080' : 'white'">
          <h1 class="text-bold" style="color: #D94080;font-size: 32px;">myInstallations</h1>
          <div>
            Create a Digital Twin for all the equipment in your line and then, access all the information you need for
            each of your installations.
          </div>
        </div>
        <div (mouseover)="onViewModule(2)" class="p-5 bg-white ml-3 border-bottom" style="border-left: 9px solid white"
          [style.borderColor]="slideShowModuleIndex == 2 ? '#EBB63F' : 'white'">
          <h1 class="text-bold" style="color: #EBB63F;font-size: 32px;">myLearning</h1>
          <div>
            Explore a rich library of videos and articles curated exclusively for the food industry. Join Knowledge
            Series, a monthly webinar hosted by subject matter experts from across the globe.
          </div>
        </div>
        <div (mouseover)="onViewModule(3)" class="p-5 bg-white ml-3" style="border-left: 9px solid white"
          [style.borderColor]="slideShowModuleIndex == 3 ? '#248480' : 'white'">
          <h1 class="text-bold" style="color: #248480;font-size: 32px;">MyProjects</h1>
          <div>
            Whether you are installing a single piece of equipment, or an entire line, keep track of the status of your
            project with all the information all your OEMs in one centralized hub. With complete access and
            transparency, you have better coordination and no surprises.
          </div>
        </div>
      </div>
      <div class="" style="width: 60%;">
        <div *ngIf="slideShowModuleIndex == 1" style="padding: 25px;background-color: #D94080;"
          class="h-100 d-flex justify-content-center">
          <img src="/assets/login_images/myInstallations_EW.png" alt="myInstallations"
            style="background-color: #d94080;" class="img-fluid no-blurry">
        </div>
        <div *ngIf="slideShowModuleIndex == 2" style="padding: 25px;background-color: #EBB63F;"
          class="h-100 d-flex justify-content-center">
          <img src="/assets/login_images/Knowledge Series.png" alt="myLearning" style="background-color: #EBB63F;"
            class="img-fluid no-blurry">
        </div>
        <div *ngIf="slideShowModuleIndex == 3" style="padding: 25px;background-color: #248480;"
          class="h-100 d-flex justify-content-center">
          <img src="/assets/login_images/myProjects_EW.png" alt="myProjects" style="background-color: #248480;"
            class="img-fluid no-blurry">
        </div>
      </div>
    </div>
  </div>

  <div style="margin: 38px 70px;">
    <div class="text-bold ml-3 form-group" style="font-size: 32px;">
      Collaborative Tools
    </div>
    <div class="d-flex mx-1 form-group">
      <div class="border round-16 mr-2 d-flex flex-column justify-content-between" style="width: 33%;">
        <div class="px-4">
          <div class="my-2 text-bold form-group" style="font-size: 24px;">
            Instantly connect with your OEMs.
          </div>
          <div class="form-group font-size-16">
            Get the right help at the right time by simply scanning the QR code on the equipment. Request spare part
            quotations with a click.
            Update & share equipment health.
          </div>
        </div>
        <div class="round-bottom-16 pt-5 position-relative"
          style="background-color: #478ac9;height: 545px;overflow: hidden;">
          <img src="/assets/login_images/qr_code_scan.png" alt="myInstallations"
            style="position: absolute; top: 15%; left: -5px;z-index: 1;width: 136px;">
          <img src="/assets/login_images/health_rating.png" alt="myInstallations"
            style="background-color: #478ac9;height: 100%;position: absolute;right: -5%;">
        </div>
      </div>
      <div class="border round-16 mx-3 d-flex flex-column justify-content-between" style="width: 33%;">
        <div class="px-4">
          <div class="my-2 text-bold form-group" style="font-size: 24px;">
            Get the right answer when you want it.
          </div>
          <div class="form-group font-size-16">
            Unlock the power of myExpert – using AI powered manuals, it acts as your assistant, getting you the answers
            to
            your questions about your equipment within an instant.
          </div>
        </div>
        <div class="round-bottom-16 pt-5" style="background-color: #478ac9;height: 545px;">
          <img src="/assets/login_images/myExpert_chat.png" alt="myInstallations"
            style="background-color: #478ac9;width: 100%;height: 100%;">
        </div>
      </div>
      <div class="border round-16 ml-2 d-flex flex-column justify-content-between" style="width: 33%;">
        <div class="px-4">
          <div class="my-2 text-bold" style="font-size: 24px;">
            Ensure timely and accurate maintenance.
          </div>
          <div class="form-group font-size-16">
            With the help of help of guided instructions in multimedia formats and digital forms to record parameters,
            you
            can be sure that your equipment is getting being optimized and managed for efficient operations
          </div>
        </div>
        <div class="round-bottom-16 pt-5" style="background-color: #478ac9;height: 545px;">
          <img src="/assets/login_images/mnt_checklist.png" alt="myInstallations"
            style="background-color: #478ac9;width: 100%;height: 100%;">
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="background-color: #eaf2fd;">
    <div class="col-md-7 position-relative" style="background-color: #d94080;overflow: hidden;">
      <!-- <div class="rotate-div1"></div> -->
      <div class="" style="margin: 8% 14%;padding-right: 20%;">
        <div class="text-bold form-group text-white" style="font-size: 40px;">
          Experience the future of food manufacturing business with My.Win
        </div>
        <button type="button" class="btn btn-success" style="font-size: 18px !important;"
          (click)="onRequestDemo(demo_request_ref)">Request a Demo</button>
      </div>
    </div>
    <div class="col-md-5 pr-3">
      <div class="round-16 px-5 py-3 bg-white" style="margin: 17% 15%;">
        <div style="width: 20px;">
          <img src="/assets/logos/mywin_logo.svg" alt="MyWin logo" class="img-fluid no-blurry">
        </div>
        <div class="text-bold form-group" style="font-size: 24px;">
          Are you an OEM looking to provide the ultimate digital experience to your customers?
        </div>
        <div>
          <button type="button" class="btn btn-success" style="font-size: 16px !important;"
            (click)="onRequestDemo(contact_us_ref)">Contact Us</button>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="first-full-height">
    <div class="yellow-triangle">
      <img src="/assets/logos/yellow_triangle.png" alt="logo" class="mt-3 no-blurry">
    </div>
    <div class="" style="position: absolute; right: 4%; top: 1%;">
    </div>
    <div class="hex-img">
      <div class="position-relative">
        <img src="/assets/logos/Polygon 1.svg" alt="logo" class="img-fluid no-blurry">
        <div style="position: absolute;top: 10px;left: 180px;right: 180px;">
          <div style="margin-top: 85px;" class="form-group text-center">
            <div class="d-flex text-center ew-tabs-wrapper">
              <div class="bg-light flex-fill py-2 hand" [class.active-tablogin]="showLoginForm"
                (click)="onTabsToggle(true);">
                Sign in
              </div>
              <div class="bg-light flex-fill py-2 hand" [class.active-tablogin]="!showLoginForm"
                (click)="onTabsToggle(false)">
                Register
              </div>
            </div>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="showLoginForm">
            <div class="form-group">
              <label for="username">Email</label>
              <input type="text" formControlName="username" placeholder="Email" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Email is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input type="password" formControlName="password" placeholder="Password" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6 pr-1">
                  <div class="custom-control">
                    <input type="checkbox" class="form-check-input">
                    <label class="">Keep me signed in</label>
                  </div>
                </div>
                <div class="col-md-6 text-center text-md-right pl-1 font-size-12">
                  <span type="button" class="text-primary" href="javascript:void(0)" style="color: #328BCB !important;"
                    (click)="showForm('Forgot')">Forgot
                    Password? </span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <button [disabled]="loading" class="btn btn-success wd100">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Sign in
              </button>
            </div>
          </form>

          <div *ngIf="!showLoginForm">

            <div class="form-group">
              <label>Name</label>
              <input type="text" class="form-control" [(ngModel)]="newUser.name" [class.input-error]="ferror.name">
              <div *ngIf="ferror.name" class="text-danger fs-085">
                Name is required
              </div>
            </div>
            <div class="form-group">
              <label>Email Address</label>
              <input type="text" class="form-control" autocomplete="new-password" [(ngModel)]="newUser.email_address"
                [class.input-error]="ferror.email || ferror.inValidEmail">
              <div *ngIf="ferror.name" class="text-danger fs-085">
                Email is required
              </div>
              <div *ngIf="ferror.inValidEmail" class="text-danger fs-085">
                Enter a valid email address
              </div>
            </div>
            <div class="form-group">
              <button type="button" class="btn btn-primary btn-block" (click)="onRegister()">Register</button>
            </div>
          </div>



        </div>
      </div>
    </div>

    <div class="right-content" style="margin-top: 12%;">
      <h1 class="home-title" style="width: 82%;">
        Grow your business
      </h1>
      <p class="mt-2" style="font-size: 20px;font-weight: 400;color: #4E4E50;">
        An integrated platform that enables you to follow <strong class="font-family-bold"
          style="color:#328BCB">effective commercial
          method</strong>, <strong class="font-family-bold" style="color:#248480">collaborate with your
          partners</strong>, and
        provide <strong class="font-family-bold" style="color:#D94080">excellent service to your customers</strong>.
      </p>
    </div>
  </div> -->

  <div style="display: none;" class="first-full-height">
    <div class="right-content" style="width: 77%;">
      <h2 class="home-title mb-3">
        Why My.Win?
      </h2>
      <div class="row">
        <div class="col col-md-5">
          <img src="/assets/logos/group_light.svg" alt="logo" height="264" class="mt-3 no-blurry">
        </div>
        <div class="col col-md-7">
          <div class="d-flex" style="margin-bottom: 2rem!important;">
            <img src="/assets/logos/step_1.svg" alt="logo" width="62" height="52" class="mr-3 no-blurry">
            <span style="font-size: 20px;color: #4E4E50;">
              Your team wastes a lot of time working on unqualified opportunities
            </span>
          </div>
          <div class="d-flex" style="margin-bottom: 2rem!important;">
            <img src="/assets/logos/step_2.svg" alt="logo" width="62" height="52" class="mr-3 no-blurry">
            <span style="font-size: 20px;color: #4E4E50;">
              You struggle to effectively engage with your global network of resellers
            </span>
          </div>
          <div class="d-flex" style="margin-bottom: 2rem!important;">
            <img src="/assets/logos/step_3.svg" alt="logo" width="62" height="52" class="mr-3 no-blurry">
            <span style="font-size: 20px;color: #4E4E50;">
              You don’t have a consolidated view and health status of your installed base
            </span>
          </div>
          <div class="d-flex">
            <img src="/assets/logos/step_4.svg" alt="logo" width="62" height="52" class="mr-3 no-blurry">
            <span style="font-size: 20px;color: #4E4E50;">
              Your team is not up to date with the latest trends and technologies in your industry
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="first-full-height">
    <div class="right-content mb-5" style="width: 80%;">
      <span class="home-title mb-6">
        How My.Win grows your business
      </span>
      <div class="row" style="margin-top: 33px; background: #FFFFFF;padding-top: 10px;">
        <div class="col-md-4  pr-4">
          <div>
            <img src="/assets/logos/globe.svg" alt="logo" height="49.21" class="mb-4 no-blurry">
          </div>
          <strong class="mb-4 font-family-bold" style="font-size: 24px;">
            New Market development
          </strong>
          <ul class="list1">
            <li>Learn about regions, customers and industry trends</li>
            <li>Effectively engage with your channels</li>
            <li>Work only on filtered opportunities and replicate your domestic success</li>
          </ul>
        </div>
        <div class="col-md-4  pr-4">
          <div>
            <img src="/assets/logos/increase.svg" alt="logo" height="49.21" class="mb-4 no-blurry">
          </div>
          <strong class="mb-4 font-family-bold" style="font-size: 24px;">
            Market penetration
          </strong>
          <ul class="list1">
            <li>Deliver excellent digital experience to your customers</li>
            <li>Continuously engage with customers to maintain best performance</li>
            <li>Identify potential upgrade opportunities</li>
          </ul>
        </div>
        <div class="col-md-4 pr-4">
          <div>
            <img src="/assets/logos/hand_shake.svg" alt="logo" height="49.21" class="mb-4 no-blurry">
          </div>
          <strong class="mb-4 font-family-bold" style="font-size: 24px;">
            Revenue from existing installations
          </strong>
          <ul class="list1">
            <li>Direct and easy access to spares enquiry from customers</li>
            <li>Access to installation health and service requests</li>
          </ul>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="first-full-height" style="margin-bottom: 150px; margin-top: 120px;">
    <div class="right-content" style="width: 80%;">
      <div class="row" id="content-title">
        <div class="col-md-6">
          <img src="/assets/logos/triangles_group.png" alt="logo" width="496" height="429"
            style="height: auto; object-fit: contain;" class="mr-2 no-blurry">
        </div>
        <div class="col-md-6" style="height: 429px;overflow: auto;">
          <span class="home-title">
            What is My.Win
          </span>
          <div class="sub-home-title mb-5" style="margin-top: 20px;">
            A comprehensive set of modules to help to
            <ul class="list2">
              <li style="color: #EBB63F;">Learn</li>
              <li style="color: #EC9E99;">Grow</li>
              <li style="color: #248480;">Partner</li>
              <li style="color: #328BCB;">Innovate</li>
              <li style="color: #D94080;">Improve Performance</li>
            </ul>
          </div>
          <div class="section1 mb-4">
            <div id="my-solutions" class="home-title mb-2" style="color: #328BCB;font-size: 32px;">
              mySolutions
            </div>
            <strong class="mb-4 font-family-bold section-subtitle">
              Organize all the tools that the sales team would ever need
            </strong>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/sol_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Catalogue all your
                products
                with
                their collaterals and use cases</span>
            </div>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/sol_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Train your team and partners
                on
                commercializing your solutions</span>
            </div>

          </div>
          <div class="section2 mb-4">
            <div class="home-title mb-2" style="color: #D94080;font-size: 32px;">
              myOpportunities
            </div>
            <strong class="mb-4 font-family-bold section-subtitle">
              Systematically go after the right opportunities with our playbook
            </strong>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/opp_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Step-by-step guided
                opportunity management</span>
            </div>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/opp_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Collaborate across your
                organization and resellers</span>
            </div>
          </div>
          <div class="section3 mb-4">
            <div class="home-title mb-2" style="color: #248480;font-size: 32px;">
              myProjects
            </div>
            <strong class="mb-4 font-family-bold section-subtitle">
              Keep your customers engaged and informed on all projects
            </strong>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/partner_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Collaborate with customers on
                data and inputs</span>
            </div>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/partner_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Provide online project status
                to
                customers</span>
            </div>
          </div>
          <div class="section4 mb-4">
            <div class="home-title mb-2" style="color: #D94080;font-size: 32px;">
              myInstallations
            </div>
            <strong class="mb-4 font-family-bold section-subtitle">
              Delight your customers with an excellent digital experience
            </strong>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/opp_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Comprehensive view of all
                installations across the globe</span>
            </div>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/opp_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Provide QR based access to
                manuals to customers</span>
            </div>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/opp_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Customers can directly
                request
                for spares and support</span>
            </div>
          </div>
          <div class="section5 mb-4">
            <div class="home-title mb-2" style="color: #248480;font-size: 32px;">
              myPartners
            </div>
            <strong class="mb-4 font-family-bold section-subtitle">
              Grow your business globally by effectively collaborating with your resellers
            </strong>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/partner_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Add resellers and assign them
                solutions, regions, and roles</span>
            </div>

            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/partner_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Monitor reseller activities
                and
                regional performance </span>
            </div>
          </div>
          <div class="section6 mb-4">
            <div class="home-title mb-2" style="color: #EBB63F;font-size: 32px;">
              myLearning
            </div>
            <strong class="mb-4 font-family-bold section-subtitle">
              Gain domain knowledge and engage with subject matter experts
            </strong>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/learn_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Knowledge Series: Learn from
                subject matter experts in monthly webinars </span>
            </div>
            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/learn_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Insights: Articles sharing
                insights about the markets and the industry </span>
            </div>

            <div class="d-flex mb-2 mt-2" style="height: 68px;">
              <img src="/assets/logos/learn_boxes.png" alt="logo" width="55" height="43" class="mr-2 no-blurry"
                style=" object-fit: contain;height: 68px;">
              <span class="align-items-stretch align-self-center font-size-18">Leverage the module to
                educate
                customers about your solutions and latest innovations</span>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div> -->

  <!-- <div class="right-content mb-5" style="width: 80%;margin-bottom: 6% !important;">
    <span class="home-title">
      Growing with My.Win
    </span>
    <div class="row mt-5 mb-5 text-center">
      <div class="col align-items-stretch align-self-center">
        <img src="/assets/logos/SVAgri.svg" alt="logo" width="104" height="104" class="mr-2 no-blurry"
          style=" object-fit: contain;">
      </div>
      <div class="col align-items-stretch align-self-center">
        <img src="/assets/logos/BluePrintAutomation.svg" alt="logo" width="179.59" height="48" class="mr-2 no-blurry"
          style=" object-fit: contain;">
      </div>
      <div class="col  align-items-stretch align-self-center">
        <img src="/assets/logos/elea.svg" alt="logo" width="141.07" height="48" class="mr-2 no-blurry"
          style=" object-fit: contain;">
      </div>
      <div class="col align-items-stretch align-self-center">
        <img src="/assets/logos/armstrong.svg" alt="logo" width="234.15" height="32" class="mr-2 no-blurry"
          style=" object-fit: contain;">
      </div>
    </div>
    <div class="row mt-3 text-center">
      <div class="col align-items-stretch align-self-center">
        <img src="/assets/logos/axtel.svg" alt="logo" width="156.56" height="56" class="mr-2 no-blurry"
          style=" object-fit: contain;">
      </div>
      <div class="col align-items-stretch align-self-center">
        <img src="/assets/logos/cybernetik.svg" alt="logo" width="213.35" height="36.2" class="mr-2 no-blurry"
          style=" object-fit: contain;">
      </div>
      <div class="col align-items-stretch align-self-center">
        <img src="/assets/logos/jonqerius.svg" alt="logo" width="178.71" height="48" class="mr-2 no-blurry"
          style=" object-fit: contain;">
      </div>
      <div class="col align-items-stretch align-self-center">
        <img src="/assets/logos/cretars.svg" alt="logo" width="147.38" height="48" class="mr-2 no-blurry"
          style=" object-fit: contain;">
      </div>
    </div>
  </div> -->

  <div
    style="background-color: #132D4B; color: white;position: absolute;width: 100%;overflow-x: hidden;z-index: 1;padding: 4%;">
    <div class="row align-items-center">
      <div class="col-md-7  pl-1">
        <div routerLink="/" class="mt-3 ml-2" style="display:inline-block;width: 90px; height: 50px">
          <img src="/assets/logos/footer_logo.svg" alt="logo" class="img-fluid no-blurry">
        </div>
        <!-- <strong class="font-family-bold">Business Enquiries: </strong> -->
        <!-- <p>Email: abhishek@enabling.win </p> -->
        <p>Empowering Food Industry for Seamless Excellence</p>
        <div class="mb-2">
          &copy; 2022 All copyrights reserved by My.Win
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <strong class="text-bold font-size-18 form-group">Contact: </strong>
          <p>Email: abhishek@enabling.win </p>
        </div>
        <div class="form-group">
          <strong class="text-bold font-size-18 form-group">Address: </strong>
          <p>190 Clemenceau Ave, #06-01 Singapore Shopping Centre, Singapore 239924 </p>
        </div>
      </div>
      <div class="col-md-4">
        <!-- <div class="ml-5">
          <strong>Quick Links </strong>
          <p>Email: abhishek@enabling.win </p>
        </div> -->
      </div>
    </div>
  </div>

  <div id="gotoTop" class="up-btn" (click)="onMoveTop()">
    <img src="/assets/logos/up_btn.png" alt="logo" width="56" height="55" class="no-blurry">
  </div>


  <div class="row" style="padding-top: 1rem!important;">
    <div class="col-md-3 offset-md-1 col-sm-6 form-group">
      <form [formGroup]="registrationSupplierForm" (ngSubmit)="onregistrationSupplierSubmit()"
        *ngIf="showRegistrationForm">
        <div class="form-group">
          <ng-select [items]="roleitems" bindLabel="name" [(ngModel)]="selected" placeholder="User Type" appendTo="body"
            (change)="getRoleValues()" [(ngModel)]="selected" [clearable]="false" notFoundText="No user type found"
            [ngClass]="{ 'is-invalid': submitted && registrationsupplier.roleType.errors }" formControlName="roleType">
          </ng-select>
          <div *ngIf="submitted && registrationsupplier.roleType.errors" class="invalid-feedback">
            <div *ngIf="registrationsupplier.roleType.errors.required">Required</div>
          </div>
        </div>

        <ng-container *ngIf="showRegistrationSupplierForm">

          <div class="form-group">
            <input type="text" formControlName="supplierusername" placeholder="Name" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && registrationsupplier.supplierusername.errors }" />
            <div *ngIf="submitted && registrationsupplier.supplierusername.errors" class="invalid-feedback">
              <div *ngIf="registrationsupplier.supplierusername.errors.required">Name is required</div>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-file">
              <input type="file" class="custom-file-input" #imageInput accept="image/*" (change)="onFileChanged($event)"
                id="customFileLangHTML" formControlName="supplierlogo" placeholder="Supplier logo" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && registrationsupplier.supplierlogo.errors }" />
              <label class="custom-file-label" for="customFileLangHTML" data-browse="Browse">Supplier Logo</label>
            </div>
            <div *ngIf="submitted && registrationsupplier.supplierlogo.errors" class="invalid-feedback">
              <div *ngIf="registrationsupplier.supplierlogo.errors.required">Supplier logo is required</div>
            </div>
          </div>
          <div class="form-group">
            <input type="text" formControlName="website" placeholder="Website" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && registrationsupplier.website.errors }" />
            <div *ngIf="submitted && registrationsupplier.website.errors" class="invalid-feedback">
              <div *ngIf="registrationsupplier.website.errors.required">Website is required</div>
              <div *ngIf="registrationsupplier.website.errors.pattern">Enter a valid URL</div>
            </div>
          </div>
          <div class="form-group">
            <input type="text" formControlName="supplieremail" placeholder="Email Address" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && registrationsupplier.supplieremail.errors }" />
            <div *ngIf="submitted && registrationsupplier.supplieremail.errors" class="invalid-feedback">
              <div *ngIf="registrationsupplier.supplieremail.errors.required">Email Address is required</div>
              <div *ngIf="registrationsupplier.supplieremail.errors?.email">Enter a valid email address</div>
            </div>
          </div>
          <div class="form-group">
            <input type="text" formControlName="contactpersonname" placeholder="Contact Person Name"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && registrationsupplier.contactpersonname.errors }" />
            <div *ngIf="submitted && registrationsupplier.contactpersonname.errors" class="invalid-feedback">
              <div *ngIf="registrationsupplier.contactpersonname.errors.required">Contact Person Name is required
              </div>
            </div>
          </div>
          <div class="form-group">
            <ng-select [items]="CountriesType" bindLabel="name" bindValue="alpha3" placeholder="HQ Country" value="-1"
              notFoundText="No country found" [clearable]="false" class="disable-clear-all"
              [ngClass]="{ 'is-invalid': submitted && registrationsupplier.hqcountry.errors }"
              formControlName="hqcountry" (change)="onHQCountryChange()"></ng-select>
            <div *ngIf="submitted && registrationsupplier.hqcountry.errors" class="invalid-feedback">
              <div *ngIf="registrationsupplier.hqcountry.errors.required">HQ Country is required</div>
            </div>

          </div>
          <div class="form-group">
            <ng-select [items]="CountriesType" bindLabel="name" bindValue="alpha3" placeholder="Supply Country"
              notFoundText="No country found" [clearable]="false" class="disable-clear-all"
              [ngClass]="{ 'is-invalid': submitted && registrationsupplier.supplycountry.errors }"
              formControlName="supplycountry"></ng-select>
            <div *ngIf="submitted && registrationsupplier.supplycountry.errors" class="invalid-feedback">
              <div *ngIf="registrationsupplier.supplycountry.errors.required">Contact Person Number is required</div>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{selctedPhoneCode}}</span>
              </div>
              <input type="text" formControlName="contactpersonnumber" placeholder="Contact Person Number"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && registrationsupplier.contactpersonnumber.errors }" />
              <div *ngIf="submitted && registrationsupplier.contactpersonnumber.errors" class="invalid-feedback">
                <div *ngIf="registrationsupplier.contactpersonnumber.errors.required">Contact Person Number is
                  required
                </div>
                <div *ngIf="registrationsupplier.contactpersonnumber.errors.pattern">Enter valid phone number between
                  6-10
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary btn-block">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Submit
            </button>
          </div>
          <div class="form-group">
            <button type="button" class="btn btn-secondary btn-block" (click)="showForm('Login')">
              Cancel
            </button>
          </div>
        </ng-container>

      </form>

      <form [formGroup]="registrationEndUserForm" (ngSubmit)="onregistrationEndUserSubmit()"
        *ngIf="showRegistrationEndUserForm">
        <div class="form-group">
          <input type="text" formControlName="endusername" placeholder="Name" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && registrationenduser.endusername.errors }" />
          <div *ngIf="submitted && registrationenduser.endusername.errors" class="invalid-feedback">
            <div *ngIf="registrationenduser.endusername.errors.required">Name is required</div>
          </div>
        </div>

        <div class="form-group">
          <input type="text" formControlName="designation" placeholder="Designation" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && registrationenduser.designation.errors }" />
          <div *ngIf="submitted && registrationenduser.designation.errors" class="invalid-feedback">
            <div *ngIf="registrationenduser.designation.errors.required">Designation is required</div>
          </div>
        </div>

        <div class="form-group">
          <input type="text" formControlName="company" placeholder="Company" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && registrationenduser.company.errors }" />
          <div *ngIf="submitted && registrationenduser.company.errors" class="invalid-feedback">
            <div *ngIf="registrationenduser.company.errors.required">Company is required</div>
          </div>
        </div>

        <div class="form-group">
          <input type="text" formControlName="enduseremail" placeholder="Email Address" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && registrationenduser.enduseremail.errors }" />
          <div *ngIf="submitted && registrationenduser.enduseremail.errors" class="invalid-feedback">
            <div *ngIf="registrationenduser.enduseremail.errors.required">Email is required</div>
            <div *ngIf="registrationenduser.enduseremail.errors.email">Enter valid email address</div>
          </div>
        </div>

        <div class="form-group">
          <input type="text" formControlName="location" placeholder="Location" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && registrationenduser.location.errors }" />
          <div *ngIf="submitted && registrationenduser.location.errors" class="invalid-feedback">
            <div *ngIf="registrationenduser.location.errors.required">Location is required</div>
          </div>
        </div>
        <div class="form-group">
          <ng-select [items]="CountriesType" bindLabel="name" bindValue="alpha3" placeholder="Country" value="-1"
            notFoundText="No country found" [clearable]="false" class="disable-clear-all"
            [ngClass]="{ 'is-invalid': submitted && registrationenduser.country.errors }" formControlName="country"
            (change)="onHQCountryChange(true)"></ng-select>
          <div *ngIf="submitted && registrationenduser.country.errors" class="invalid-feedback">
            <div *ngIf="registrationenduser.country.errors.required">Country is required</div>
          </div>

        </div>
        <div class="form-group">
          <div class="input-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{selctedPhoneCode}}</span>
            </div>
            <input type="text" formControlName="contactnumber" placeholder="Contact Number" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && registrationenduser.contactnumber.errors }" />
            <div *ngIf="submitted && registrationenduser.contactnumber.errors" class="invalid-feedback">
              <div *ngIf="registrationenduser.contactnumber.errors.required">Contact Number is required</div>
              <div *ngIf="registrationenduser.contactnumber.errors.pattern">Enter valid phone number between 6-10
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button [disabled]="loading" class="btn btn-primary wd100">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Submit
          </button>
        </div>
        <div class="form-group">
          <button type="button" class="btn btn-secondary btn-block" (click)="showForm('Login')">
            Cancel
          </button>
        </div>
      </form>

      <div>
        <!-- <h6 class="footer"> 2020 {{ewTitle | uppercase}}. All rights reserved</h6> -->
      </div>
    </div>
    <div class="col-md-8 col-sm-6 form-group text-center">
      <!-- <img src="/assets/img/loginImage.jpg" class="img-fluid" alt="login-image"> -->
    </div>
  </div>
</div>

<div *ngIf="mobile_view">
  <div class="container-fluid">
    <div class="row" style="padding: 15px !important;">
      <div class="col-md-3 offset-md-1 col-sm-6 form-group">
        <div class="mb-2">
          <img style="height: 70px !important;     width: 100%;
    object-fit: contain;" src="/assets/logos/colour_ogo/Colour Logo (transparent bg) -02.png" alt="logo"
            class="no-blurry">
        </div>
        <div class="mb-2">
          <span class="home-title" style="font-size: 18px;line-height: 28px;">
            <!-- Grow your business with my.Win -->
            Enhance your equipment performance by collaborating with your OEMs.
          </span>
          <p class="mt-2 mb-4" style="font-size: 14px;font-weight: 400;color: #4E4E50;">
            <!-- An integrated platform that enables you to follow a <strong style="color:#328BCB">scalable commercial
              process</strong>, <strong style="color:#248480">collaborate with your partners</strong>, and
            provide <strong style="color:#D94080">excellent service to your customers</strong>. -->
            Tailored for the food industry, My.Win provides a single platform for you and your OEMs to work seamlessly
            and ensure optimal efficiency, reliability and quality of your equipment.
          </p>
        </div>
        <div class="d-flex text-center ew-tabs-wrapper">
          <div class="bg-light flex-fill py-2 hand" [class.active-tablogin]="showLoginForm"
            (click)="onTabsToggle(true);">
            Sign in
          </div>
          <div class="bg-light flex-fill py-2 hand" [class.active-tablogin]="!showLoginForm"
            (click)="onTabsToggle(false)">
            Register
          </div>
        </div>
      </div>
      <div style="    width: 100%;
    margin-left: 5%;
    margin-right: 5%;">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="showLoginForm">
          <div class="form-group">
            <label for="username">Email</label>
            <input type="text" formControlName="username" placeholder="Email" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Email is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" formControlName="password" placeholder="Password" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
            <div class="row mt-3">
              <div class="col pl-2 pr-1">
                <div class="custom-control">
                  <input type="checkbox" class="form-check-input">
                  <label class="">Keep me signed in</label>
                </div>
              </div>
              <div class="col font-size-12 pl-1 text-right">
                <span type="button" class="text-primary" href="javascript:void(0)" style="color: #328BCB !important;"
                  (click)="showForm('Forgot')">Forgot
                  Password? </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-success wd100">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Sign in
            </button>
          </div>
        </form>

        <div *ngIf="!showLoginForm">

          <div class="form-group">
            <label>Name</label>
            <input type="text" class="form-control" [(ngModel)]="newUser.name" [class.input-error]="ferror.name">
            <div *ngIf="ferror.name" class="text-danger fs-085">
              Name is required
            </div>
          </div>
          <div class="form-group">
            <label>Email Address</label>
            <input type="text" class="form-control" autocomplete="new-password" [(ngModel)]="newUser.email_address"
              [class.input-error]="ferror.email || ferror.inValidEmail">
            <div *ngIf="ferror.name" class="text-danger fs-085">
              Email is required
            </div>
            <div *ngIf="ferror.inValidEmail" class="text-danger fs-085">
              Enter a valid email address
            </div>
          </div>
          <div class="form-group">
            <button type="button" class="btn btn-primary btn-block" (click)="onRegister()">Register</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<ng-template #demo_request_ref>
  <div class="modal-header">
    <h4 class="modal-title pull-left sub-head-20">Request a Demo</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group px-4">
      <div class="form-group" style="width: 44px;">
        <img src="/assets/logos/mywin_logo.svg" alt="MyWin logo" class="img-fluid no-blurry">
      </div>
      <div class="form-group" style="font-size: 24px;">
        If you are an OEM give the ultimate digital experience to your customers please email us at:
      </div>
      <div class="text-center p-1" style="background-color: #F7F7F7;font-size: 24px;">
        <a href="mailto:oem.consult@mywin.cloud">oem.consult@mywin.cloud</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contact_us_ref>
  <div class="modal-header">
    <h4 class="modal-title pull-left sub-head-20">Contact Us</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group px-4">
      <div class="form-group" style="width: 20px;">
        <img src="/assets/logos/mywin_logo.svg" alt="MyWin logo" class="img-fluid no-blurry">
      </div>
      <div class="form-group" style="font-size: 24px;">
        To get a demo of My.Win and know how it can help you in the food manufacturing business, email us at:
      </div>
      <div class="text-center p-1" style="background-color: #F7F7F7;font-size: 24px;">
        <a href="mailto:demo@mywin.cloud">demo@mywin.cloud</a>
      </div>
    </div>
  </div>
</ng-template>