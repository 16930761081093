<div class="p-3">
    <div class="form-group">
        <strong class="text-secondary" [innerHTML]="message | safeHTML"></strong>
    </div>
    <div class="clearfix">
        <div class="float-right">
            <button *ngIf="!show_proceed" class="btn btn-primary mr-2" (click)="onConfirm()">{{yes_text}}</button>
            <button *ngIf="show_proceed" class="btn btn-primary mr-2" (click)="onConfirm()">Proceed</button>
            <button class="btn btn-secondary" (click)="onCancel()">{{no_text}}</button>
        </div>
    </div>
</div>