import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTruncateContent]'
})
export class TruncateContentDirective {
  @Input() maxLines: number = 3;
  
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit() {
    const element = this.el.nativeElement;
    const lineHeight = window.getComputedStyle(element).lineHeight;
    const maxHeight = parseInt(lineHeight, 10) * this.maxLines; // Set the max height for three lines

    if (element.clientHeight > maxHeight) {
      while (element.clientHeight > maxHeight) {
        element.textContent = element.textContent.replace(/\W*\s(\S)*$/, '...');
      }
    }
  }

}
