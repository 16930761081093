import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class UrlService {
    current_url = '';
    previous_url = '';

    setCurrentPreviousUrl(url) {
        this.previous_url = this.current_url;
        this.current_url = url;
    }
}