// globals.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, iif, of } from 'rxjs';
import { CacheService } from './cache.service';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
//import { product } from "../model/product.model";


@Injectable({
  providedIn: "root"
})
export class Data {

  constructor(private http: HttpClient, private cacheService: CacheService) { }

  getData(url, httpOptions) {
    return this.http.get<any>(url, httpOptions);
  }

  // getData1(url: string, params: HttpParams, useCache = false): Observable<any> {
  //   return iif(
  //     () => useCache,
  //     this.getCachedData(url, params),
  //     this.fetchAndCacheData(url, params)
  //   );
  // }

  // private getCachedData(url: string, params: HttpParams): Observable<any> {
  //   debugger
  //   const cachedData = this.cacheService.get(url, params);
  //   return cachedData ? of(cachedData) : this.fetchAndCacheData(url, params);
  // }

  // private fetchAndCacheData(url: string, params: HttpParams): Observable<any> {
  //   return this.http.get(url, { params }).pipe(
  //     tap((data) => {
  //       this.cacheService.set(url, params, data);
  //     })
  //   );
  // }

  getDataWithBody(url, body, httpOptions) {
    return this.http.get<any>(url, httpOptions);
  }

  postData(url, body, params) {
    return this.http.post<object[]>(url, body, params);
  }

  postParams(url, params) {
    return this.http.post<object[]>(url, params);
  }
  putData(url, body, params) {
    return this.http.put<object[]>(url, body, params);
  }

  deleteData(url) {
    return this.http.delete<object[]>(url);
  }

  searchList(searchText$: Observable<string>,
    fetchFunctionOptions: {
      fetchFunction: (options: any) => Observable<any>;
      searchTextKey: string;
    }): Observable<any> {
    return searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((searchText) => {
        debugger
        const options = {
          [fetchFunctionOptions.searchTextKey]: searchText,
        };
        return fetchFunctionOptions.fetchFunction(options);
      })
    );
  }

}
