import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '../app.config';
import { Observable } from 'rxjs';
import { IndividualModule } from './individual.module';

@Injectable({
  providedIn: IndividualModule
})
export class IndividualService {
  baseUrl = '';

  constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient) {
    this.baseUrl = this.config.baseUrl;
  }

  getRequestStatus(): Observable<any> {
    const url = this.baseUrl + 'send_request_status/';
    return this.http.get<any>(url);
  }

  sendDemoRequest(request): Observable<any> {
    const url = this.baseUrl + 'demo_request/';
    return this.http.post(url, request);
  }

  sendOrgRequest(): Observable<any> {
    const url = this.baseUrl + 'org_request/';
    return this.http.post(url, null);
  }
}
