<div class="row no-gutters page-head sticky-top bg-white">
    <div class="col-md-6 d-flex">
        <h4 class="head-title">Users - <span *ngIf="user_id">Edit</span><span *ngIf="!user_id">Add New</span> User</h4>
    </div>
    <div class="col-md-6 text-right w-value">
        <button type="button" class="btn btn-outline-secondary mr-2" (click)="onCancel()">Cancel</button>
        <button *ngIf="!is_view" type="button" class="btn btn-success mr-2" (click)="onSave()">Save</button>
        <button *ngIf="is_view" class="btn btn-primary mr-2" (click)="onEditUser()">
            <span class="fa fa-pencil-square-o mr-2"></span> Edit
        </button>
        <ng-container *ngIf="isUpdate">
            <button *ngIf="user.is_subscribed && !is_view" class="btn btn-primary mr-2" (click)="onApproveUser()">
                <span class="fa fa-thumbs-o-up"></span> Approve
            </button>
            <button *ngIf="!is_view" class="btn btn-primary mr-2" (click)="onCloneUser()">
                <span class="fa fa-clone"></span> Clone
            </button>
            <button *ngIf="!is_view" class="btn btn-primary mr-2" (click)="onRestetUserPwd()">
                <span class="fa fa-key"></span> Reset Password
            </button>
            <button *ngIf="!is_view" class="btn btn-primary mr-2" (click)="onActiveToggle()">
                <span class="fa fa-user-times"></span>
                <span *ngIf="user.active == 'Active'"> Deactivate</span>
                <span *ngIf="user.active != 'Active'"> Activate</span>
            </button>
        </ng-container>
    </div>
</div>
<div class="container-fluid p-0">
    <div class="layout-padding">
        <div class="card round-16 form-group">
            <div class="card-body">
                <div class="row form-group">
                    <div class="col-md-6 form-group">
                        <div class="form-group">
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="user.name"
                                [class.input-error]="ferror.name" [readOnly]="is_view">
                            <div *ngIf="ferror.name" class="text-danger fs-085">
                                Name is required
                            </div>
                        </div>
                        <div *ngIf="current_user.user_type == 'Admin'" class="form-group">
                            <label>Organization Type <span class="text-danger">*</span></label>
                            <app-ew-select [options]="org_types" [(ngModel)]="user.user_type" optionText="name"
                                optionValue="id" [read_only]="is_view" (change)="onOrgTypeChange()">
                            </app-ew-select>
                            <div *ngIf="ferror.user_type" class="text-danger fs-085">
                                Organization Type is required
                            </div>
                        </div>
                        <ng-container *ngIf="current_user.user_type == 'Admin'">
                            <div *ngIf="user.user_type != '6'" class="form-group">
                                <label>Organization <span class="text-danger">*</span></label>
                                <app-ew-select [options]="organizations | filter:user.user_type:'user_type'"
                                    [(ngModel)]="user.organization" optionText="name" optionValue="id"
                                    [read_only]="is_view" (change)="onOrganizationChange()">
                                </app-ew-select>
                                <!-- <select class="form-control" [(ngModel)]="user.organization"
                                (change)="onOrganizationChange()"
                                [class.input-error]="ferror.organization || ferror.org_user_slots_maxed"
                                [disabled]="is_view">
                                <ng-container *ngIf="user.user_type=='4'">
                                    <option value="">Other</option>
                                </ng-container>
                                <ng-container *ngIf="user.user_type!='4'">
                                    <option value=""></option>
                                    <option [value]="org.id"
                                        *ngFor="let org of organizations | filter:user.user_type:'user_type'">
                                        {{org.name}}
                                    </option>
                                </ng-container>
                            </select> -->
                                <div *ngIf="ferror.organization" class="text-danger fs-085">
                                    Organization is required
                                </div>
                                <div *ngIf="ferror.org_user_slots_maxed" class="text-danger fs-085">
                                    User slots reached to the maximum
                                </div>
                            </div>
                        </ng-container>
                        <div class="form-group">
                            <label>User Role <span class="text-danger">*</span></label>
                            <app-ew-select [options]="roles | filter:user.user_type:'user_type'"
                                [(ngModel)]="user.ew_role" optionText="name" optionValue="id" [read_only]="is_view">
                            </app-ew-select>
                            <!-- <select class="form-control" [(ngModel)]="user.ew_role" [class.input-error]="ferror.ew_role"
                                [disabled]="is_view">
                                <ng-container *ngIf="user.user_type=='4'">
                                    <option value="">Other</option>
                                </ng-container>
                                <ng-container *ngIf="user.user_type!='4'">
                                    <option value=""></option>
                                    <option [value]="role.id"
                                        *ngFor="let role of roles | filter:user.user_type:'user_type'">
                                        {{role.name}}</option>
                                </ng-container>
                            </select> -->
                            <div *ngIf="ferror.ew_role" class="text-danger fs-085">
                                User Role is required
                            </div>
                        </div>
                        <!-- <div *ngIf="user.user_type != '6'" class="form-group">
                            <label>Subscription Enabled</label>
                            <div *ngIf="is_view" class="mt-2 mb-4 hand">
                                <span *ngIf="!user.subscription_enabled"
                                    class="fa fa-toggle-off fa-lg text-secondary"></span>
                                <span *ngIf="user.subscription_enabled"
                                    class="fa fa-toggle-on fa-lg text-ew-primary"></span>
                            </div>
                            <div *ngIf="!is_view" class="mt-2 mb-4 hand"
                                (click)="user.subscription_enabled = !user.subscription_enabled">
                                <span *ngIf="!user.subscription_enabled"
                                    class="fa fa-toggle-off fa-lg text-secondary"></span>
                                <span *ngIf="user.subscription_enabled"
                                    class="fa fa-toggle-on fa-lg text-ew-primary"></span>
                            </div>
                        </div> -->
                        <div *ngIf="user.user_type == '3' || current_user.user_type == 'Food Manufacturer'"
                            class="form-group">
                            <label>Hierarchy Level</label>
                            <!-- <app-ew-select [options]="hierarchy_levels" [(ngModel)]="user.hierarchy_type"
                                optionText="name" optionValue="id"
                                [read_only]="!user.organization || !user.user_type || is_view"
                                (change)="onHierarchyLevelChange(true)">
                            </app-ew-select> -->
                            <select class="form-control" [(ngModel)]="user.hierarchy_type"
                                (change)="onHierarchyLevelChange(true)" [disabled]="is_view">
                                <option value="all">All Locations</option>
                                <option *ngIf="org_groups.length > 0" value="group">Group</option>
                                <option *ngIf="org_locations.length > 0" value="location">Location</option>
                            </select>
                        </div>
                        <div *ngIf="current_user.user_type == 'Admin'" class="form-group">
                            <div (click)="onUserManagementToggle()" class="border round-8 p-2"
                                [class.bg-readonly]="is_view" [class.hand]="!is_view">
                                <span>User Management Access</span>
                                <span *ngIf="!user.user_management"
                                    class="fa fa-toggle-off fa-lg text-secondary float-right"
                                    style="padding-top: 3px;"></span>
                                <span *ngIf="user.user_management"
                                    class="fa fa-toggle-on fa-lg text-ew-primary float-right"
                                    style="padding-top: 3px;"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <div class="form-group">
                            <label>Email <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="user.email_address"
                                [disabled]="isUpdate" [class.input-error]="ferror.email_address" [readOnly]="is_view">
                            <div *ngIf="ferror.email_address" class="text-danger fs-085">
                                Valid Email is required
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Designation <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="user.designation"
                                [class.input-error]="ferror.designation" [readOnly]="is_view">
                            <div *ngIf="ferror.designation" class="text-danger fs-085">
                                Designation is required
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Country <span class="text-danger">*</span></label>
                            <app-ew-select [options]="countries" [(ngModel)]="user.location" optionText="name"
                                optionValue="alpha3" [read_only]="is_view" (change)="onCountryChange()">
                            </app-ew-select>
                            <div *ngIf="ferror.location" class="text-danger fs-085">
                                Country is required
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Contact Number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{selctedPhoneCode}}</span>
                                </div>
                                <input type="text" class="form-control cont_bor" [(ngModel)]="user.contact_number"
                                    [class.input-error]="ferror.contact_number" [readOnly]="is_view">
                            </div>
                            <div *ngIf="ferror.contact_number" class="text-danger fs-085">
                                Please enter a valid contact number
                            </div>
                        </div>
                        <div class="form-check p-2 px-4">
                            <input type="checkbox" class="form-check-input" style="width: 18px;height: 18px;"
                                [(ngModel)]="user.contact_number_have_whatsapp" id="isWhatsAppNoSame"
                                [disabled]="is_view">
                            <label class="form-check-label pt-1" for="isWhatsAppNoSame">Use same number for
                                Whatsapp</label>
                        </div>
                        <div *ngIf="!user.contact_number_have_whatsapp" class="form-group">
                            <label>Whatsapp Number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{selctedPhoneCode}}</span>
                                </div>
                                <input type="text" class="form-control cont_bor" [(ngModel)]="user.whatsapp_number"
                                    [class.input-error]="ferror.whatsapp_number" [readOnly]="is_view">
                            </div>
                            <div *ngIf="ferror.contact_number" class="text-danger fs-085">
                                Please enter a valid contact number
                            </div>
                        </div>
                        <!-- <div *ngIf="user.subscription_enabled" class="form-group">
                            <label>Subscription</label>
                            <input type="text" class="form-control" [(ngModel)]="org_subscription" disabled>
                        </div> -->
                        <div *ngIf="user.user_type == '3' && user.hierarchy_type != 'all'" class="form-group">
                            <label>Hierarchy Name <span class="text-danger">*</span></label>
                            <app-ew-select [options]="hierarchyValueList" [(ngModel)]="user.hierarchy_value"
                                optionText="name" optionValue="id" [read_only]="is_view">
                            </app-ew-select>
                            <div *ngIf="ferror.hierachy_value" class="text-danger fs-085">
                                Hierarchy Name is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>