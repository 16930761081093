import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IndividualRoutingModule } from './individual-routing.module';
import { IndividualComponent } from './individual.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { IndividualProfileComponent } from './individual-profile/individual-profile.component';
import { SharedModule } from '../shared/shared.module';
import { InsightsIndComponent } from './my-learning-ind/insights-ind/insights-ind.component';
import { InsightDetailsIndComponent } from './my-learning-ind/insights-ind/insight-details-ind.component';
import { KnowledgeSeriesIndComponent } from './my-learning-ind/knowledge-series-ind/knowledge-series-ind.component';
import { KnowledgeSeriesDetailsIndComponent } from './my-learning-ind/knowledge-series-ind/knowledge-series-details-ind.component';


@NgModule({
  declarations: [
    IndividualComponent,
    IndividualProfileComponent,
    InsightsIndComponent,
    InsightDetailsIndComponent,
    KnowledgeSeriesIndComponent,
    KnowledgeSeriesDetailsIndComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    IndividualRoutingModule,
    PopoverModule.forRoot(),
  ]
})
export class IndividualModule { }
