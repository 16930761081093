import { Component, OnInit } from '@angular/core';
import { ConfirmDialogService } from './confirm-dialog.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(private confirmDialog: ConfirmDialogService) { }
  message = '';
  show_proceed = false;
  yes_text = 'Ok';
  no_text = 'Cancel';
  ngOnInit(): void {
    this.message = this.confirmDialog.message;
    this.show_proceed = this.confirmDialog.show_proceed;
    this.yes_text = this.confirmDialog.yes_text;
    this.no_text = this.confirmDialog.no_text;
  }

  onConfirm() {
    this.confirmDialog.confirm();
  }

  onCancel() {
    this.confirmDialog.cancel();
  }
}
