import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Business } from '../service/Business.service';
import { HelperService } from '../service/helper.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentService } from '../service/document.service';

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss'],
  providers: [BsModalRef]
})
export class DocumentViewComponent implements OnInit, OnDestroy {
  @HostListener('window:load', ['$event'])
  onWindowLoad(event: Event) {
    this.updateWindowDimensions();
    this.adjustImageSize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.updateWindowDimensions();
    this.adjustImageSize();
  }

  @Input() blobName: string;
  @Output() close = new EventEmitter<void>();

  blob_name = '';
  file_name = '';
  blob_url = '';
  loading = false;
  is_video_file = false;
  is_image_file = false;
  is_pdf_file = false;
  pdf_data = null;

  windowHeight: number;
  windowWidth: number;

  constructor(
    private route: ActivatedRoute,
    private business: Business,
    private helper: HelperService,
    private documentService: DocumentService,
    public bsModalRef?: BsModalRef
  ) { }

  ngOnInit(): void {
    (document.getElementsByTagName("BODY")[0] as any).style.overflow = "hidden";
    this.route.queryParams.subscribe((params) => {
      this.blob_name = params['blob_name'] ? params['blob_name'] : this.blob_name;
      if (this.blob_name && this.file_name) {
        // this.file_name = this.blobName.split('-').slice(5).join('-');
        this.loading = true;
        this.is_pdf_file = this.file_name.endsWith('.pdf')
        if (this.is_pdf_file) {
          this.business.getAzureBlobBase64(this.blob_name).subscribe((resp) => {
            this.loading = false;
            this.pdf_data = `data:application/pdf;base64,${resp.blob_base64}`;
          }, () => this.loading = false);
        } else {
          this.business.getAzureBlobUrl(this.blob_name).subscribe((resp) => {
            this.loading = false;
            this.is_video_file = this.helper.isVideoFileBlob(this.file_name);
            this.is_image_file = this.helper.isImageFileBlob(this.file_name);
            this.blob_url = resp?.blob_url;
          }, () => this.loading = false);
        }
      }
    });
  }

  ngOnDestroy(): void {
    (document.getElementsByTagName("BODY")[0] as any).style.overflow = "visible";
  }

  private updateWindowDimensions() {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  }

  onImageLoad() {
    this.adjustImageSize()
  }

  adjustImageSize() {
    if (!this.is_image_file) return;
    const container: any = document.querySelector('.image-container');
    const image: any = document.querySelector('.image-container img');

    container.style.height = this.windowHeight + 'px';
    const containerWidth = this.windowWidth //container.offsetWidth;
    const containerHeight = this.windowHeight // container.offsetHeight;
    console.log(containerWidth, containerHeight)

    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;
    console.log(imageWidth, imageHeight)

    if (imageWidth > containerWidth || imageHeight > containerHeight) {

      const widthRatio = containerWidth / imageWidth;
      const heightRatio = containerHeight / imageHeight;

      const scale = Math.min(widthRatio, heightRatio);

      image.style.width = imageWidth * scale + 'px';
      image.style.height = imageHeight * scale + 'px';
    } else {
      image.style.width = imageWidth + 'px';
      image.style.height = imageHeight + 'px';
    }
  }

  onRightClick(event) {
    event.preventDefault();
  }

  onBeforeOpen() {

  }

  onClose() {
    (document.getElementsByTagName("BODY")[0] as any).style.overflow = "visible";
    this.documentService.closeDocument();
  }

}
