import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_guards';
import { IndividualHomeComponent } from './individual-home/individual-home.component';
import { IndividualProfileComponent } from './individual-profile/individual-profile.component';
import { IndividualComponent } from './individual.component';
import { InsightsIndComponent } from './my-learning-ind/insights-ind/insights-ind.component';
import { InsightDetailsIndComponent } from './my-learning-ind/insights-ind/insight-details-ind.component';
import { KnowledgeSeriesIndComponent } from './my-learning-ind/knowledge-series-ind/knowledge-series-ind.component';
import { KnowledgeSeriesDetailsIndComponent } from './my-learning-ind/knowledge-series-ind/knowledge-series-details-ind.component';


const routes: Routes = [
  {
    path: '', component: IndividualComponent, children: [
      { path: '', component: IndividualHomeComponent, canActivate: [AuthGuard] },
      { path: 'individual-profile', component: IndividualProfileComponent, canActivate: [AuthGuard] },
      { path: 'insights', component: InsightsIndComponent, canActivate: [AuthGuard] },
      { path: 'insight-details', component: InsightDetailsIndComponent, canActivate: [AuthGuard] },
      { path: 'knowledge-series', component: KnowledgeSeriesIndComponent, canActivate: [AuthGuard] },
      { path: 'knowledge-series-details', component: KnowledgeSeriesDetailsIndComponent, canActivate: [AuthGuard] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IndividualRoutingModule { }
