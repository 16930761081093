import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../service/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt access token if available
        let loggedInUserInfo = JSON.parse(sessionStorage.getItem('loggedInUserInfo'));
        //let loggedInUserInfo = this.authenticationService.currentUserValue;
        if (loggedInUserInfo && loggedInUserInfo.access_token) {
            request = request.clone({
                setHeaders: { 
                    Authorization:'Bearer '+loggedInUserInfo.access_token
                }
            });
            
            let loggedInTime = JSON.parse(sessionStorage.getItem('currentLoginTime'));
            let currentTime = (new Date).getTime();
            let expirationTime = loggedInUserInfo.expires_in + loggedInTime;
            //let expirationTime = 120000 + loggedInTime;
            
            let timeDiffrence = expirationTime - currentTime;
            if(timeDiffrence < 60000){
                let Confirm = confirm('Session will expire. Are you want to continue?');
                if(Confirm){ 
                    //refresh token will further implement
                    //set new current time
                    sessionStorage.setItem('currentLoginTime',JSON.stringify(currentTime));
                }else{
                    //clear session
                    sessionStorage.removeItem('currentLoginTime');
                    sessionStorage.removeItem('loggedInUserInfo');
                    window.location.reload();
                }    
            }
            
            if(currentTime > expirationTime){
                alert('Session timeout');
                sessionStorage.removeItem('currentLoginTime');
                sessionStorage.removeItem('loggedInUserInfo');
                window.location.reload();
            }
        }

        return next.handle(request);
    }
}