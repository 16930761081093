import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: Array<any>, searchValue: string, column?: string): Array<any> {
    if (value instanceof Array && searchValue) {
      //check for column authentic
      if(column) {
        return value.filter((item) => item[column].toString().toLowerCase().includes(searchValue.toString().toLowerCase()));
      } else {
        return value.filter((item) => item.toString().toLowerCase().includes(searchValue.toString().toLowerCase()));
      }
    }
    return value;
  }

}
