<div class="app-container">
  <!-- {{ (message | async)?.notification.title }}
  {{ (message | async)?.notification.body }} -->
  <alert></alert>
  <div class="main" role="main">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="show_footer" class="ew-footer">
    <div class="fs-085 text-secondary">2022 {{ewTitle | uppercase}}. All rights reserved</div>
  </div>
  <div *ngIf="show_help_form" class="help-form">
    <div class="help-head">
      Help
      <img src="/assets/icons/remove_cross_white.png" class="float-right no-blurry mt-1 hand" width="12" height="12"
        (click)="onCancelHelp()">
    </div>
    <div class="help-body">
      <div class="text-light1 form-group">
        Tell us what you need help with and our team will get in touch with you as soon as possible
      </div>
      <div class="form-group">
        <textarea class="form-control" rows="10" placeholder="Enter your message here" [(ngModel)]="help_form.message"
          [class.input-error]="help_form_error.message"></textarea>
        <div *ngIf="help_form_error.message" class="fs-085 text-danger">
          Please enter the message
        </div>
      </div>
      <div class="form-group">
        <div class="my-2 bg-light rounded p-2 position-relative" style="height: 104px">
          <div style="border: 2px dashed lightgray;height: 100%;overflow:auto"
            class="rounded d-flex flex-column justify-content-center text-secondary hand">
            <input *ngIf="upload_mode" type="file" id="sol-doc-input" class="hidden-file"
              (change)="onDocumentChange($event.target.files)">
            <div *ngFor="let file of uploaded_files" class="bg-white py-1 px-3 border-bottom">
              <div class="d-flex">
                <div class="align-self-center mr-2">
                  <span><img width="32" height="32" [src]="helper.getExtensionType(file.name)"
                      class="hand svg-white"></span>
                </div>
                <div class="flex-grow-1 text-truncate">
                  <strong>{{file.name}}</strong>
                  <div class="text-secondary fs-085">{{getFileSize(file.size)}}</div>
                </div>
                <div>
                  <span class="fa fa-times mr-1 hand text-secondary"
                    (click)="onRemoveUploadedFile(file, $event)"></span>
                </div>
              </div>
            </div>
            <div *ngIf="upload_mode" class="text-center">
              <span class="fa fa-file mb-2"></span>
              <div class="text-light1">Drag & drop or click to upload image</div>
            </div>
          </div>
        </div>
        <div *ngIf="uploaded_files_error.length > 0" class="text-danger my-2 fs-085">
          <ng-container *ngFor="let err of uploaded_files_error">
            <p *ngIf="err.size_limit">{{err.name}} exceeds maximum size limit of 100MB</p>
            <p *ngIf="err.type">{{err.name}} is not a valid image</p>
          </ng-container>
        </div>
      </div>
      <div>
        <button *ngIf="submitting" class="btn btn-success" disabled>
          <span class="fa fa-circle-o-notch fa-spin fa-spin mr-1"></span> Submitting
        </button>
        <button *ngIf="!submitting" class="btn btn-success" (click)="onSubmit()">Submit</button>
      </div>
    </div>
  </div>
  <div *ngIf="show_help_button" class="btn-help" (click)="onHelp()">
    <img src="/assets/logos/help_btn.png" class="no-blurry" width="48" height="48">
  </div>
</div>