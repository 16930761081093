import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../app.config';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    baseUrl = '';

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private http: HttpClient
    ) {
        this.baseUrl = this.config.baseUrl;
    }

    register(user): Observable<any> {
        const url = this.baseUrl + 'account/signup/';
        return this.http.post(url, user);
    }

    subscribe(user): Observable<any> {
        const url = this.baseUrl + 'account/subscribe/';
        return this.http.post(url, user);
    }

    logUserActivity(page_data: any): Observable<any> {
        const url = this.baseUrl + 'access_log_list/';
        return this.http.post<any>(url, page_data);
    }

    getUserActivity(): Observable<any> {
        const url = this.baseUrl + 'access_log_list/';
        return this.http.get<any>(url);
    }

    submitHelpMessage(help_form): Observable<any> {
        const url = this.baseUrl + 'help_notification/';
        return this.http.post<any>(url, help_form);
    }
}