

<div class="modal-header" background-color = "#ffffff">
  <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="hideModel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ message }}
  </div>
  <div *ngIf="showInput">
    <div class="form-group" ng-form="subForm">
       
       <input type="text" name="name" [(ngModel)]="name" class="form-control input100" placeholder="Type your first name and last name here" autocomplete="off" required/>
       <span class="invalid-feedback" *ngIf="isResponsible"> required</span>
    </div>
  </div>
  <div class="modal-footer" *ngIf="!isAlert && !isBLEAlert">
    <button type="button"   class="btn btn-danger" (click)="hideModel()">{{btnCancelText}}</button>
    <button type="button" class="btn btn-primary"  (click)="confirm()">{{btnOkText}}</button>
  </div>
  <div class="modal-footer" *ngIf="isAlert">
      <button type="button" class="btn btn-primary"  (click)="confirm()">{{btnOkText}}</button>
  </div>
  <div class="text-center" *ngIf="isBLEAlert" >
    <button type="button"  class="login100-form-btn"  (click)="selectErorOption(1)">WiFi LED is steady blue</button>
    <button type="button" class="login100-form-btn"  (click)="selectErorOption(2)">WiFi LED is blinking slow(every 2 seconds)</button> 
    <button type="button" class="login100-form-btn"  (click)="selectErorOption(3)">WiFi LED is blinking false(every .25 seconds)</button> 
    <button type="button" class="login100-form-btn"  (click)="selectErorOption(4)">WiFi LED is Off)</button>
</div>
