import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const EW_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EwFileUploadComponent),
  multi: true
};

const noop = () => {
};


@Component({
  selector: 'app-ew-file-upload',
  templateUrl: './ew-file-upload.component.html',
  styleUrls: ['./ew-file-upload.component.scss'],
  providers: [EW_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class EwFileUploadComponent implements OnInit, ControlValueAccessor {
  @Input() label = '';
  @Input() set isBase64(value: boolean) {
    this._isBase64Img = value;
  }

  @Input() set IsSmallInput(value: boolean) {
    this._isSmallInput = value;
  }

  @Input() set isDocFile(value: boolean) {
    this._isDocFile = value;
  }
  @Output() upload = new EventEmitter<any>();

  fileUpload: File = null;
  fileUrl: "";
  base64Img = '';
  _isBase64Img = false;
  fileName = '';
  _isSmallInput = false;
  _isDocFile = false;

  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  constructor() { }

  ngOnInit(): void {
  }

  writeValue(value: any): void {
    this.fileUrl = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  uploadFile(files: FileList) {
    this.fileUpload = files.item(0);
    this.upload.emit(this.fileUpload);
    this.fileName = this.fileUpload.name;
    this.getBase64Img(this.fileUpload);    
  }

  deleteFile() {
    this.fileUpload = null;
    this.fileUrl = "";
    this.onChange(this.fileUpload);
  }

  getBase64Img(file) {
    // const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    // const pattern = /image-*/;
    // if (file.size > (1024 * 1024)) {
    // }
    
    var reader = new FileReader();
    // if (!file.type.match(pattern)) {
    //   alert('invalid format');
    //   return;
    // }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.base64Img = reader.result;

    const outputFile = this._isBase64Img ? this.base64Img : this.fileUpload;
    this.onChange(outputFile);
  }
}
