import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../service/authentication.service';
import { AlertService } from '../service/alert.service';
import { param } from 'jquery';
import { ConfirmedValidator } from '../resetpassword/confirmed.validator';

@Component({
  selector: 'app-setpwd',
  templateUrl: './setpwd.component.html',
  styleUrls: ['./setpwd.component.scss']
})
export class SetPwdComponent implements OnInit {
  setPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  setSubmit = false;
  setqueryParams: any;
  keyParam: any;


  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private alertService: AlertService) {

      }

  ngOnInit() {

    let key = '';
      this.route.queryParams.subscribe(params => {
            key = params['key'];
        });

      this.setPasswordForm = this.formBuilder.group({
        key: [key, [Validators.required]],
        password: ['', Validators.required],
        confirm_password: ['', Validators.required]
      }, {
          validator: ConfirmedValidator('password', 'confirm_password')
        })
  }


  // convenience getter for easy access to form fields
  get set() { return this.setPasswordForm.controls; }



    setPasswordSubmit() : void {
        this.setSubmit = true;

        // stop here if form is invalid
        if (this.setPasswordForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.setPassword(this.set.key.value, this.set.password.value, this.set.confirm_password.value)
            .pipe(first())
            .subscribe(data => {

                    let message = "Password set successfully."
                    this.router.navigate(['/login']);

            },
            error => {
                let message = "Incorrect credential, please try again."
                this.alertService.error(message);
                this.loading = false;
            });
    }
}
