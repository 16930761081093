<div class="top-section">
    <img src="/assets/logos/yellow_triangle_slice1.png" class="triangle-slice">
    <div class="form-group">
        <div class="mb-2">
            <span style="text-decoration: underline;cursor: pointer;"
                (click)="onLinkClick('my-learning')">myLearning</span>
        </div>
        <div class="text-bold form-group" style="font-size: 40px;color: #EBB63F;line-height: 1;">
            Insights
        </div>
        <div class="font-size-18">
            We continuously gather and publish information on each food industry segment in our Insights module.
            With our in-depth industry knowledge, you will have access to useful industry insights on a regular basis.
        </div>
    </div>
</div>

<div class="layout-padding">
    <div class="sub-head-20 form-group">
        Explore Insights
    </div>
    <div class="row mb-3">
        <div class="col-md-4 col-sm-10">
            <input type="search" class="form-control" placeholder="Search..." (keyup)="onSearchKeyUp($event)"
                (search)="onSearchKeyUp($event)">
        </div>
        <div class="col-md-2">
            <div class="position-relative">
                <button class="btn btn-outline-custom-primary filter-height hand" id="drop-open"
                    (click)="showFilters = !showFilters">
                    <span class="fa-lg" style="vertical-align: 0%;"><img id="drop-open" [class.fillcolor]="bol_filter"
                            src="/assets/svg-icon/ic_filter.svg"></span>
                    <ng-container *ngIf="bol_filter">&nbsp;&nbsp;
                        <ng-container *ngIf="filter_count == 1">
                            {{filter_count}} Filter Applied
                        </ng-container>
                        <ng-container *ngIf="filter_count != 1">
                            {{filter_count}} Filters Applied
                        </ng-container>
                    </ng-container>
                </button>
                <div *ngIf="showFilters" class="minifilter-form-box clearfix" (clickOutside)="showFilters = false">
                    <span class="fa fa-caret-up fa-2x text-white filter-dialog-show"></span>
                    <div class="form-group">
                        <label>Technologies</label>
                        <app-ew-multi-select2 [options]="technologies" optionValue="id" optionText="name"
                            [(ngModel)]="filter_technologies">
                        </app-ew-multi-select2>
                    </div>
                    <div class="form-group">
                        <label>Industries</label>
                        <app-ew-multi-select2 [options]="industries" optionValue="id" optionText="name"
                            [(ngModel)]="filter_industries" (change)="onIndustryChange()">
                        </app-ew-multi-select2>
                    </div>
                    <div class="form-group">
                        <label>Primary Products</label>
                        <app-ew-multi-select2 [options]="filtered_product_list" optionValue="id" optionText="name"
                            [(ngModel)]="filter_products">
                        </app-ew-multi-select2>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button (click)="onClearFilters()" class="btn btn-sm btn-outline-secondary mr-2 w-50">
                            Reset filters
                        </button>
                        <button (click)="onApplyFilters()" class="btn btn-sm btn-outline-success ml-2 w-50">
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 mb-4  d-flex" *ngFor="let insight of insights" (click)="onViewDetails(insight)">
            <div class="insight-item flex-fill">
                <div class="border form-group" style="height: 215px;">
                    <img [src]="insight.thumbnail_image_blob | ewurl | async" class="no-blurry bg-white"
                        style="width: 100%; height: 100%;">
                </div>
                <div appTruncateContent [maxLines]="2" class="sub-head-20 form-group" style="overflow: hidden;">
                    {{insight.title}}
                </div>
                <div appTruncateContent class="text-light0 form-group" [innerHTML]="insight.content">
                </div>
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                        <button type="button" class="btn btn-sm btn-warning rounded-0 text-white"
                            (click)="onViewDetails(insight)">Read more</button>
                    </div>
                    <div class="text-light1">
                        {{insight.month_of_installation}}, {{insight.year_of_installation}}
                    </div>
                </div>
                <hr>
                <div *ngIf="insight.authors_set[0]" class="d-flex align-items-center">
                    <div class="border mr-3" style="width: 48px; height: 48px; border-radius: 50%; overflow: hidden;">
                        <img *ngIf="insight.authors_set[0].profile_photo_blob"
                            [src]="insight.authors_set[0].profile_photo_blob | ewurl | async" class="no-blurry"
                            style="width: 100%; height: auto;">
                        <span *ngIf="!insight.profile_photo_blob" class="profile-pic-letter">
                            {{insight.authors_set[0].name[0]}}
                        </span>
                    </div>
                    <div class="flex-grow-1">
                        <div class="sub-head-16">{{insight.authors_set[0].name}}</div>
                        <div class="text-light1 font-size-14">{{insight.authors_set[0].designation}}</div>
                        <div class="text-light1 font-size-14">{{insight.authors_set[0].organization}}</div>
                    </div>
                    <div *ngIf="insight.authors_set.length > 1" class="align-self-start">
                        <div class="border d-flex align-items-center justify-content-center"
                            style="height: 32px;width: 32px;border-radius: 24px;font-size: 16px;font-weight: 700;">
                            +{{insight.authors_set.length - 1}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>