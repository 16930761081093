import {HttpHeaders} from "@angular/common/http";
export class AppConstants {
    
  public static get AUTH_HEADERS(): any { 
    return new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
  };
  public static get API_HEADERS(): any { 
    return new HttpHeaders().set('Content-Type', 'application/json');
  };

  public static MESSAGES(messageKey) : any {
    const MessageArray = {
      "NOT_FOUND" : "No record(s) found.",
      "SEARCH_MSG" : "Please provide search values from the filters provided above."
    }
    return MessageArray[messageKey];
  }
}
