import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from '../service/alert.service';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../service/authentication.service';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;
    isModalShown: boolean = false;
    alertTitle: string;
    alertMesaage: string;
    type: string;
    toasts: ActiveToast<any>;
    constructor(private alertService: AlertService, private toastr: ToastrService, private authenticationService: AuthenticationService) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => {
            if (this.toasts)
                this.toastr.remove(this.toasts.toastId);

            this.toasts = undefined;
            if (message != undefined && message.type == 'success') {

                this.toasts = this.toastr.success(message.text, '', {
                    timeOut: 5000,
                    positionClass: "toast-top-center"
                });
            } else if (message != undefined && message.type == 'error') {
                this.toasts = this.toastr.error(message.text, '', {
                    timeOut: 5000,
                    positionClass: "toast-top-center"
                });
            } else if (message != undefined && message.type == 'warning') {
                this.toasts = this.toastr.warning(message.text, '', {
                    timeOut: 5000,
                    positionClass: "toast-center"
                });
            }
        });
    }
    hideModel() {
        this.isModalShown = false;
        //  this.activeModal.close(false);
    }

    confirm() {
        // if(this.alertTitle == "LogOut") {
        //     this.authenticationService.logout(); 
        //     window.location.reload();
        // }else {

        // }
        // this.activeModal.close(true);
        this.isModalShown = false;
    }
    close() {
        this.isModalShown = false;
    }



    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}